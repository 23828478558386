import React, { HTMLAttributes, RefObject, useEffect, useRef } from 'react';

import { useAudioVideo } from 'amazon-chime-sdk-component-library-react';

import { createCss } from '../../styles/video-styles';
import VideoLabel from './video-label';
import { Stack } from '@mui/material';

interface Props
    extends Omit<HTMLAttributes<HTMLDivElement>, 'css'> {
    tileId: number;
    tileCount: number;
    viewHeight: number;
    viewWidth: number;
    name?: string;
}

export const RemoteVideo: React.FC<React.PropsWithChildren<Props>> = ({
    className,
    tileId,
    tileCount,
    viewHeight,
    viewWidth,
    name,
}) => {
    const audioVideo = useAudioVideo();
    const videoEl = useRef<HTMLVideoElement>(null);

    const [css, setCss] = React.useState(createCss(9, 16, viewHeight, viewWidth, tileCount));
    const [currentTileCount, setCurrentTileCount] = React.useState(tileCount);

    const useApplyVideoObjectFit = (videoEl: RefObject<HTMLVideoElement>) => {
        useEffect(() => {
            const onLoaded = () => {
                if (!videoEl.current) {
                    return;
                }

                const height = videoEl.current.videoHeight;
                const width = videoEl.current.videoWidth;

                videoEl.current.style.objectFit = height > width ? 'contain' : 'cover';
            }

            videoEl.current?.addEventListener('loadedmetadata', onLoaded);

            return () =>
                videoEl.current?.removeEventListener('loadedmetadata', onLoaded);
        }, [videoEl]);
    }

    useApplyVideoObjectFit(videoEl);

    useEffect(() => {
        if (!videoEl.current) {
            return;
        }

        setCss(createCss(videoEl.current.height, videoEl.current.width, viewHeight, viewWidth, tileCount));
        setCurrentTileCount(tileCount);
    }, [tileCount, videoEl, viewHeight, viewWidth, currentTileCount]);

    useEffect(() => {
        if (!audioVideo || !videoEl.current) {
            return;
        }

        audioVideo.bindVideoElement(tileId, videoEl.current);

        return () => {
            const tile = audioVideo.getVideoTile(tileId);
            if (tile) {
                audioVideo.unbindVideoElement(tileId);
            }
        };
    }, [audioVideo, tileId]);

    return (
        <Stack
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
                backgroundColor: "black",
                alignContent: "center",
                justifyContent: "center",
            }}>
            <video
                ref={videoEl}
                className={`ch-remote-video--${tileId} ${className || ''}`}
                style={css}
            >
            </video >
            {name && <VideoLabel
                name={name}
                zIndex={10}
                right={false} />}
        </Stack>
    );
};

export default RemoteVideo;