import { Paper, Stack, Typography } from "@mui/material";
import React from "react";
import ForgotPasswordForm from "../components/login/forgot-password-form";
import ResetPasswordForm from "../components/login/reset-password-form";
import { AuthContext } from "../context/auth-context";
import toast from "react-hot-toast";
import { useNavigate, useSearchParams } from "react-router-dom";


const ForgotPasswordPage: React.FC = () => {

    const [searchParams] = useSearchParams();
    const email = searchParams.get("email") || "";

    const { resetPassword, confirmForgotPassword } = React.useContext(AuthContext);

    const navigate = useNavigate();

    const forgotPasswordHandler = async (email: string) => {
        console.log(email);

        resetPassword(email, {
            onSuccess: () => {
                console.log("Password reset email sent");
                toast.success("Password reset email sent", { duration: 5000 });
                navigate('/forgotpassword?email=' + email);
            },
            onFailure: (error) => {
                console.error("Password reset email not sent", error);
                toast.error("Password reset email not sent", { duration: 5000 });
            }
        });
    }

    const confirmForgotPasswordHandler = async (verificationCode: string, newPassword: string) => {
        console.log(verificationCode, newPassword);

        confirmForgotPassword(email, verificationCode, newPassword, {
            onSuccess: (success) => {
                console.log(success);
                navigate("/login");
            },
            onFailure: (error) => {
                console.error("Password reset failed", error);
                toast.error("Password reset failed", { duration: 5000 });
            }
        });
    }

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            sx={{
                minHeight: "100vh",
                backgroundColor: "#EBEBF4",
                backgroundImage: "radial-gradient(#323AD2 0.75px, #EBEBF4 0.75px)",
                backgroundSize: "15px 15px",
            }}
        >
            <Paper
                elevation={24}
                sx={{
                    maxWidth: 400,
                    px: 8,
                    py: 4,
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    borderRadius: 2,
                    backgroundSize: "100% 100%",
                    backgroundPosition: "0px 0px",
                    backgroundImage:
                        "linear-gradient(0deg, #E0E0E0FF 0%, #FFFFFFFF 100%)",
                }}
            >
                <Stack spacing={3} alignItems="center">
                    <Typography variant="h4">
                        Reset Password
                    </Typography>
                    {
                        email.length > 0 ? (
                            <ResetPasswordForm
                                resetPassword={confirmForgotPasswordHandler}
                            />
                        ) : (
                            <ForgotPasswordForm
                                forgotPassword={forgotPasswordHandler}
                                intialEmail=""
                            />
                        )
                    }

                </Stack>
            </Paper>
        </Stack>
    )
}

export default ForgotPasswordPage;