import { Organisation, User } from "../../types";
import CustomTag from "../custom-tag";
import { getOrganisationTagTooltip } from "./organisation-tag";


interface CompactOrganisationTagProps {
    organisation?: Organisation
    users: User[]
}

const CompactOrganisationTag: React.FC<CompactOrganisationTagProps> = ({
    organisation,
    users,
}) => {

    const name = organisation?.name || "No organisation"
    const text = `${name} (${users.length})`
    const tooltip = getOrganisationTagTooltip(name, users.length)

    return (
        <CustomTag
            color={organisation?.color || "#000000"}
            text={text}
            tooltip={tooltip}
            sx={{
                width: "fit-content",
                minWidth: "80px",
            }}
        />
    );
}

export default CompactOrganisationTag;