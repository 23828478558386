import React, { useContext, useEffect } from "react";

import { useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { DataMessage } from "amazon-chime-sdk-js";

import { useGetPhotoUrls } from "../../store";
import { MeetingInfoContext } from "../../context/meeting-info-context";

import toast from "react-hot-toast";
import DownloadImageModal from "./download-image-modal";
import ViewModelModal from "../three/view-model-modal";
import { Stack } from "@mui/material";

interface DownloadImageProps {
    showText?: boolean;
}

const DownloadPhotoUrlModal: React.FC<DownloadImageProps> = ({
    showText = true
}) => {

    const [downloadPhotoURLs, setDownloadPhotoURLs] = React.useState<string[]>([]);

    const [downloadModelURLs, setDownloadModelURLs] = React.useState<string[]>([]);

    const meetingManager = useMeetingManager();

    const { userId } = useContext(MeetingInfoContext);

    const meetingId = meetingManager.meetingSessionConfiguration?.externalMeetingId || "";

    const { data: photoUrls } = useGetPhotoUrls(userId, meetingId);

    useEffect(() => {
        if (!photoUrls) {
            return;
        }

        console.log("Photo URLs", photoUrls);

        const newUrls = photoUrls as string[];

        if (!newUrls) {
            return;
        }

        const newDownloadURLs = [...downloadPhotoURLs];
        const newModelURLs = [...downloadModelURLs];

        newUrls.forEach((url: string) => {

            const isPhoto = url.includes("content-type=image")

            if (isPhoto && !downloadPhotoURLs.includes(url)) {
                newDownloadURLs.push(url);
            }

            if (!isPhoto && !downloadModelURLs.includes(url)) {
                newModelURLs.push(url);
            }
        });

        if (newDownloadURLs.length !== downloadPhotoURLs.length) {
            setDownloadPhotoURLs(newDownloadURLs);
        }

        if (newModelURLs.length !== downloadModelURLs.length) {
            setDownloadModelURLs(newModelURLs);
        }

    }, [photoUrls, meetingManager.meetingSessionConfiguration?.externalMeetingId]);

    const receiveUrl = (message: DataMessage) => {
        const messageContents = message.text();

        const url = messageContents.replace(/['"]+/g, '');

        if (url.length === 0) {
            return;
        }

        if (url.includes("content-type=image")) {
            addPhotoUrl(url);
        }
        else {
            addModelUrl(url);
        }
    }

    const addPhotoUrl = (url: string) => {

        if (downloadPhotoURLs.includes(url)) {
            return;
        }

        const newDownloadURLs: string[] = [...downloadPhotoURLs, url];

        setDownloadPhotoURLs(newDownloadURLs);

        toast.success("New image available for download")
    }

    const addModelUrl = (url: string) => {

        if (downloadModelURLs.includes(url)) {
            return;
        }

        const newDownloadURLs: string[] = [...downloadModelURLs, url];

        setDownloadModelURLs(newDownloadURLs);

        toast.success("New model available for viewing")
    }

    if (meetingManager.audioVideo) {
        meetingManager.audioVideo.realtimeUnsubscribeFromReceiveDataMessage("download-url");
        meetingManager.audioVideo.realtimeSubscribeToReceiveDataMessage("download-url", receiveUrl);
    }

    return (
        <Stack
            direction={"row"}
            alignItems={"center"}
            justifyItems={"center"}
            spacing={2}
        >
            <DownloadImageModal
                showText={showText}
                downloadURLs={downloadPhotoURLs} />
            <ViewModelModal
                showText={showText}
                imageSources={downloadModelURLs} />
        </Stack>
    )
}

export default DownloadPhotoUrlModal;