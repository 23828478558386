import React, { FC } from 'react';

import { Button, Stack, SxProps, Theme, Tooltip } from '@mui/material';

export interface ControlButtonProps {
    children?: React.ReactNode;
    disabled?: boolean;
    active: boolean;
    activeLabel: string;
    inactiveLabel: string;
    activeColor?: string;
    inactiveColor?: string;
    activeTooltip?: string;
    inactiveTooltip?: string;
    activeIcon?: React.ReactNode;
    inactiveIcon?: React.ReactNode;
    showText?: boolean;
    sx?: SxProps<Theme>;
    onClick: () => void;
}

export const ControlButton: FC<
    React.PropsWithChildren<ControlButtonProps>
> = ({
    activeIcon,
    inactiveIcon,
    children,
    active,
    activeLabel,
    inactiveLabel,
    activeTooltip,
    inactiveTooltip,
    showText,
    disabled = false,
    activeColor = "lightgreen",
    inactiveColor = "lightgray",
    sx,
    onClick,
}) => {

        const tooltip = active ?
            activeTooltip ?
                activeTooltip :
                activeLabel :
            inactiveTooltip ?
                inactiveTooltip :
                inactiveLabel

        const icon = active ? activeIcon : inactiveIcon;
        const label = active ? activeLabel : inactiveLabel;

        let color = active ? activeColor : inactiveColor;

        if (disabled) {
            color = "#979797";
        }

        return (
            <Tooltip title={tooltip}>
                <Stack
                    direction="row"
                    sx={{
                        borderRadius: 1,
                        boxShadow: 1,
                        backgroundColor: color,
                        alignItems: "center",
                    }}>
                    <Button
                        disabled={disabled}
                        onClick={onClick}
                        aria-label={label}
                        sx={{
                            color: 'black',
                            fontWeight: 600,
                            fontSize: "1.25rem",
                            lineHeight: 1.4,
                            height: "100%",
                            width: "100%",
                            justifyContent: showText ? "left" : "center",
                            padding: 1,
                            ...sx,
                        }}
                    >
                        {icon}
                        {showText ? label : null}
                    </Button>
                    {children}
                </Stack >
            </Tooltip>
        );
    };

export default ControlButton;