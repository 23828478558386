import React, { useContext, useEffect, useRef } from 'react';

import { useAudioVideo, useLocalVideo } from 'amazon-chime-sdk-component-library-react';

import { createCss } from '../../styles/video-styles';

import NoRemoteView from './no-remote-view';
import VideoLabel from './video-label';

import { MeetingInfoContext } from '../../context/meeting-info-context';
import LocalViewTile from './local-view-tile';

interface CustomLocalViewProps {
    showNotSharingMessage: boolean;
    showNamePlate: boolean;
    viewHeight: number;
    viewWidth: number;
}

const CustomLocalView: React.FC<CustomLocalViewProps> = ({
    showNotSharingMessage,
    showNamePlate,
    viewHeight,
    viewWidth,
}) => {

    const { errorMessage } = React.useContext(MeetingInfoContext);
    const { isVideoEnabled } = useLocalVideo();

    const getErrorMessage = () => {
        if (errorMessage) {
            return errorMessage;
        }

        if (!showNotSharingMessage) {
            return null;
        }

        if (!isVideoEnabled) {
            return "You are not sharing your video";
        }

        return null;
    }

    const finalErrorMessage = getErrorMessage();

    return (
        <div
            style={{
                position: "relative",
                width: "100%",
                height: "100%",
            }}>
            {finalErrorMessage == null ?
                <>
                    <LocalViewTile showNamePlate={showNamePlate} viewHeight={viewHeight} viewWidth={viewWidth} />
                    {showNamePlate && isVideoEnabled && <VideoLabel
                        name="You"
                        zIndex={100}
                        right={true} />}
                </>
                : <NoRemoteView message={finalErrorMessage} backgroundColor='lightGrey' />}
        </div >
    );
};

export default CustomLocalView;