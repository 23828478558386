import React from "react";

import { Stack } from "@mui/material";

import ExcludeSelfVideoTileGrid from "../components/meeting/exclude-self-tile-grid";

import MobileControlBar from "../components/meeting/mobile-control-bar";


const MobileMeeting: React.FC = () => {

    return (
        <Stack
            spacing={0.5}
            sx={{
                minHeight: "100vh",
                minWidth: "100vw",
                height: "100%",
                width: "100%",
                backgroundColor: "#2E3033",
                alignItems: "start",
                justifyContent: "start"
            }}
        >
            <Stack sx={{
                width: "95%",
                height: "95%",
                alignItems: "center",
                justifyContent: "center",
                padding: 1
            }}>
                <ExcludeSelfVideoTileGrid viewHeight={65} viewWidth={65} showNamePlate />
                <MobileControlBar showText={false} />
            </Stack>
        </Stack>
    );

};

export default MobileMeeting;