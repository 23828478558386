import { Stack, SxProps, Tooltip, Typography } from "@mui/material";
import { PropsWithChildren } from "react";


interface CustomTagProps {
    text: string;
    secondaryText?: string
    color: string;
    icon?: JSX.Element;
    tooltip?: string;
    sx?: SxProps
}

const CustomTag: React.FC<PropsWithChildren<CustomTagProps>> = ({
    text,
    secondaryText,
    color,
    icon,
    tooltip,
    sx,
    children
}) => {
    return (
        <Stack
            paddingX={"0.6rem"}
            paddingY={"0.4rem"}
            borderRadius={"0.5rem"}
            alignItems={"center"}
            justifyContent={"left"}
            minWidth={"fit-content"}
            sx={{
                backgroundColor: color,
                ...sx
            }}>
            <Tooltip title={tooltip} arrow>
                <Stack
                    direction={"row"}
                    spacing={1}
                    justifyItems={"center"}
                    alignItems={"center"}
                    width={"100%"}>
                    {icon}
                    <Stack
                        direction={"column"}
                        width={"100%"}>
                        <Typography variant="body1">
                            <b>{text}</b>
                        </Typography>
                        {secondaryText && <Typography variant="body1">
                            {secondaryText}
                        </Typography>}
                    </Stack>
                    {children}
                </Stack>
            </Tooltip>
        </Stack>
    )
}

export default CustomTag