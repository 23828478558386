import { useContext } from "react";

import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

import { MeetingInfoContext } from "../../context/meeting-info-context";

import TelehealthStyledButton from "../telehealth-styled-button";

interface ShowSelfProps {
    disabled?: boolean;
    right: boolean;
    zIndex: number;
}

const StyledIconShowSelfButton: React.FC<ShowSelfProps> = ({
    disabled = false,
    right,
    zIndex,
}) => {

    const { showSelf, setShowSelf } = useContext(MeetingInfoContext);

    const toggleShowSelf = () => {
        setShowSelf(!showSelf);
    }

    const label = showSelf ? "Hide Self" : "Show Self";

    return (
        <div style={{
            position: "absolute",
            backdropFilter: `blur(20px)`,
            backgroundColor: `rgba(46, 47, 52, 0.85)`,
            borderRadius: `0.25rem`,
            color: `white`,
            bottom: "0.5rem",
            left: right ? `` : "0.5rem",
            right: right ? `0.5rem` : ``,
            width: "fit-content",
            zIndex: zIndex,
            justifySelf: "start",
        }}>
            <TelehealthStyledButton
                onClick={toggleShowSelf}
                disabled={disabled}
                icon={showSelf ? <VisibilityOffIcon color="primary" /> : <VisibilityIcon color="primary" />}
                label={label}
                showText={false} />
        </div>
    );
}

export default StyledIconShowSelfButton;