import { PropsWithChildren } from "react";

import { Stack } from "@mui/material"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';

import { User, userHasPermission, UserType } from "../types"

import TelehealthToolbar from "./telehealth-toolbar"
import CalendarOptionsDropdown from "./calendar/calendar-options-dropdown"
import NavigationIconButton from "./navigation-icon-button";
import ClearCallLogo from "./clear-call-logo";

interface NavigationTelehealthToolbarProps {
    page: string
    currentUser?: User
}

const NavigationTelehealthToolbar: React.FC<PropsWithChildren<NavigationTelehealthToolbarProps>> = ({
    page,
    currentUser,
    children
}) => {

    let path = ""
    let buttonLabel = ""
    let showOrganisation = false

    if (currentUser && currentUser.userType === UserType.OrgAdmin) {
        path = '/organisation'
        buttonLabel = "Organisation Settings"
        showOrganisation = page !== 'organisation'
    }

    if (currentUser && currentUser.userType === UserType.SysAdmin) {
        path = '/adminorganisations'
        buttonLabel = 'Organisations'
        showOrganisation = page !== 'adminorganisations'
    }

    return (
        <TelehealthToolbar >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                width={"100vw"}
                padding={2}>
                <ClearCallLogo size={40} />
                <Stack
                    id="navigation-toolbar-stack"
                    direction="row"
                    spacing={2}
                    alignItems={"center"}
                    justifyItems={"center"}
                    height={"100%"}>
                    <Stack direction="row" spacing={0} height={"100%"}>
                        {showOrganisation &&
                            userHasPermission(UserType.OrgAdmin, currentUser) &&
                            <NavigationIconButton
                                tooltip={buttonLabel}
                                icon={<SupervisorAccountIcon sx={{
                                    color: "#055480",
                                }} />}
                                navigationPath={path}
                            />}
                        {page !== "calendar" &&
                            <NavigationIconButton
                                tooltip="Calendar"
                                icon={<CalendarMonthIcon sx={{
                                    color: "#055480",
                                }} />}
                                navigationPath="/dashboard"
                            />}
                    </Stack>
                    {children}
                    <CalendarOptionsDropdown
                        page={page}
                        currentUser={currentUser} />
                </Stack>
            </Stack>
        </TelehealthToolbar>
    )
}

export default NavigationTelehealthToolbar