import { Stack, Typography } from "@mui/material"

import { Meeting, User } from "../../types"
import { MeetingJoinInfo, MeetingJoinType } from "../../types"

import JoinMeetingButton from "./join-meeting-button"

interface DialogueJoinMeetingButtonProps {
    canJoinNow: boolean
    meeting: Meeting,
    currentUser?: User,
    joinInfo: MeetingJoinInfo,
    canJoinMessage: string,
    buttonType: MeetingJoinType
}

const DialogueJoinMeetingButton: React.FC<DialogueJoinMeetingButtonProps> = ({
    canJoinNow,
    meeting,
    currentUser,
    joinInfo,
    canJoinMessage,
    buttonType
}) => {

    return (
        <Stack
            spacing={0}
            alignItems={"center"}
            justifyItems={"center"}
            sx={{
                backgroundColor: "#e6e6e6",
                borderRadius: "0.5rem",
            }}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyItems={"space-between"}
                minHeight={"75px"}
                spacing={2}>
                <Typography variant={"h5"}>
                    {canJoinMessage}
                </Typography>
                {canJoinNow && currentUser &&
                    <JoinMeetingButton
                        meeting={meeting}
                        joinedUsers={joinInfo?.joinedIds || []}
                        userId={currentUser?.userId || ""}
                        userName={currentUser?.fullName || ""}
                        jobTitle={currentUser?.jobTitle || ""}
                        meetingType={buttonType} />}
            </Stack>
        </Stack >
    )
}

export default DialogueJoinMeetingButton