import React, { useEffect } from "react";

import {
    MeetingStatus,
    useMeetingManager,
} from "amazon-chime-sdk-component-library-react";

import NoRemoteView from "./no-remote-view";
import { MeetingInfoContext } from "../../context/meeting-info-context";

const MeetingStatusDisplay: React.FC = () => {

    const meetingManager = useMeetingManager();

    const { errorMessage, setErrorMessage } = React.useContext(MeetingInfoContext);

    const [listenerSet, setListenerSet] = React.useState(false);

    useEffect(() => {

        if (!meetingManager) {
            return;
        }

        if (listenerSet) {
            return;
        }

        meetingManager.subscribeToMeetingStatus(setErrorMessageFromStatus);
        setListenerSet(true);

    }, [meetingManager]);

    useEffect(() => {
        console.log("MeetingStatusDisplay useEffect errorMessage:", errorMessage);
        setErrorMessageFromStatus(meetingManager.meetingStatus);
    }, [errorMessage]);

    const setErrorMessageFromStatus = (status: MeetingStatus) => {

        if (errorMessage) {
            setErrorMessage(errorMessage);
            return;
        }

        if (status === MeetingStatus.Succeeded) {
            setErrorMessage(null);
            return;
        }

        if (status === MeetingStatus.Failed) {
            setErrorMessage("Failed to connect.");
            return;
        }

        if (status === MeetingStatus.Loading) {
            setErrorMessage("Connecting...");
            return;
        }

        if (status === MeetingStatus.Ended) {
            setErrorMessage("Meeting has ended.");
            return;
        }

        if (status === MeetingStatus.JoinedFromAnotherDevice) {
            setErrorMessage("Joined From Another Device.");
            return;
        }

        if (status === MeetingStatus.Reconnecting) {
            setErrorMessage("Reconnecting...");
            return;
        }

        if (status === MeetingStatus.TerminalFailure) {
            setErrorMessage("Terminal Failure.");
            return;
        }
    }

    return (
        <> </>
    );
};

export default MeetingStatusDisplay;
