import React from "react";
import { createContext, PropsWithChildren } from "react";
import { MeetingJoinInfo } from "../types";

const defaultMeetingJoinInfo: MeetingJoinInfo = {
    joinedIds: [],
    attendeesId: [],
    confirmedAttendeesId: [],
    startTime: new Date()
}

export type JoinedAttendeeContextProps = {
    getJoinedAttendees: (meetingId: string) => MeetingJoinInfo;
    setJoinedAttendees: (meetingId: string, attendees: MeetingJoinInfo) => void;
}

export const JoinedAttendeeContext = createContext<JoinedAttendeeContextProps>({
    getJoinedAttendees: () => defaultMeetingJoinInfo,
    setJoinedAttendees: () => { }
});

export const JoinedAttendeeProvider: React.FC<PropsWithChildren> = ({ children }) => {

    const [joinedAttendeesDictionary, setJoinedAttendeesDictionary] = React.useState<{ [key: string]: MeetingJoinInfo }>({})

    const getJoinedAttendees = (meetingId: string) => {
        return joinedAttendeesDictionary[meetingId] || []
    }

    const setJoinedAttendees = (meetingId: string, attendees: MeetingJoinInfo) => {
        setJoinedAttendeesDictionary({ ...joinedAttendeesDictionary, [meetingId]: attendees })
    }

    return (
        <JoinedAttendeeContext.Provider
            value={{
                getJoinedAttendees,
                setJoinedAttendees
            }}
        >
            {children}
        </JoinedAttendeeContext.Provider>
    )
}
