import React, { Suspense, useRef } from "react";

import { Card, CircularProgress, Stack } from "@mui/material"

import { Vector3 } from "three";

import { urlExpired } from "../upload/url-expired";

import ExpirationCard from "./expiration-card";
import ModelControlPanel from "./model-control-panel";
import { Canvas } from "@react-three/fiber";
import { Html, OrbitControls } from "@react-three/drei";
import ModelFromUrl from "./model-from-url";
import PointsLine from "./points-line";


interface ModelPanelProps {
    modelUrl: string;
    download?: boolean;
}

const ModelPanel: React.FC<ModelPanelProps> = ({
    modelUrl,
}: ModelPanelProps) => {

    const [modelPosition, setModelPosition] = React.useState(new Vector3(0, 0, 0));
    const [distance, setDistance] = React.useState<number>(-1);
    const [angle, setAngle] = React.useState<number>(-1);
    const [points, setPoints] = React.useState<Vector3[]>([]);

    const [showMeasureTool, setShowMeasureTool] = React.useState<boolean>(false);

    const orbitControlsRef = useRef<any>(null);

    const pointsChanged = (event: any) => {
        const point = event.point;

        const newPoints =
            points.length === 2 ?
                [point] :
                [...points, point];

        setPoints(newPoints);

        if (newPoints.length < 2) {
            setDistance(-1);
            return;
        }

        const distance = newPoints[0].distanceTo(newPoints[1]);
        const angleBetween = newPoints[0].angleTo(newPoints[1]);

        // convert angle between to degrees
        const angleBetweenDegrees = angleBetween * (180 / Math.PI);

        setDistance(distance);
        setAngle(angleBetweenDegrees);
    }

    const toggleMeasureTool = () => {
        setShowMeasureTool(!showMeasureTool);
        setPoints([]);
        setDistance(-1);
        setAngle(-1);
    }

    const expired = urlExpired(modelUrl);

    const resetOrbitControls = () => {

        console.log("resetting orbit controls", orbitControlsRef);

        if (orbitControlsRef.current) {
            orbitControlsRef.current.reset();
        }
    };

    return (
        <Stack
            spacing={1}
            height={"75vh"}
            width={"50vw"}
            alignItems={"center"}
            justifyItems={"center"}>
            <ModelControlPanel
                showMeasureTool={showMeasureTool}
                onMeasureToolToggle={toggleMeasureTool}
                distance={distance}
                angle={angle}
                onCenterModel={resetOrbitControls} />
            <Card sx={{
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                justifyItems: "center",
                backgroundColor: "#333333",
            }}>
                {expired &&
                    <ExpirationCard message="Model has expired" />}
                <Canvas>
                    <Suspense fallback={
                        <Html position={[0, 0, 0]}>
                            <CircularProgress />
                        </Html>}>
                        <ModelFromUrl
                            url={modelUrl}
                            position={modelPosition}
                            expired={expired}
                            onClick={pointsChanged} />
                    </Suspense>
                    <ambientLight intensity={4} />
                    <OrbitControls target={new Vector3(0, 0, -14)} ref={orbitControlsRef} />
                    <PointsLine points={points} visible={showMeasureTool} />
                    {/* <mesh position={new Vector3(0, 0, -14)} visible={true}>
                <sphereGeometry args={[1, 36, 36]} />
                <meshBasicMaterial color="green" />
            </mesh> */}
                </Canvas>
            </Card>
        </Stack>
    );
}

export default ModelPanel;