import * as React from "react";

import { Button, Grid, Stack, TextField } from "@mui/material";

import { useFormik } from "formik";
import * as Yup from "yup";

import { useGetCurrentUser, useUpdateUser } from "../../store";
import { User } from "../../types";

interface SetDetailsProps {
  updateText?: string;
  onSuccess?: () => void;
  onError?: (error: any) => void;
  requireUpdate?: boolean;
}

const SetDetails: React.FC<SetDetailsProps> = ({
  updateText = "Update Details",
  onSuccess,
  onError,
  requireUpdate = true
}) => {

  const { data: currentUser } = useGetCurrentUser();

  const { mutate: updateUser } = useUpdateUser(onSuccess, onError);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fullName: currentUser?.fullName || "",
      jobTitle: currentUser?.jobTitle || "",
    },
    //require length above 0
    validationSchema: Yup.object({
      fullName: Yup.string()
        .required("Full Name is required")
        .min(2, "Full Name is too short")
        .max(24, "Full Name is too long"),
      jobTitle: Yup.string()
        .required("Job Title is required")
        .max(24, "Job Title is too long"),
    }),
    onSubmit: (values) => {
      const { fullName, jobTitle } = values;

      if (!currentUser) {
        return;
      }

      if (fullName === currentUser.fullName && jobTitle === currentUser.jobTitle) {
        if (onSuccess) {
          onSuccess();
        }
        return;
      }

      const updatedUser: User = {
        ...currentUser,
        jobTitle,
        fullName,
      }

      updateUser(updatedUser);
    },
  });

  const fullNameChanged = currentUser?.fullName !== formik.values.fullName;
  const jobTitleChanged = currentUser?.jobTitle !== formik.values.jobTitle;

  let updateDisabled = !fullNameChanged && !jobTitleChanged

  if (!requireUpdate) {
    updateDisabled = false;
  }

  return (
    <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} padding={2}>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="fullName"
              name="fullName"
              label="Full Name"
              variant="standard"
              autoComplete="fullName"
              value={formik.values.fullName}
              onChange={formik.handleChange}
              error={formik.touched.fullName && Boolean(formik.errors.fullName)}
              helperText={formik.touched.fullName && formik.errors.fullName}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="jobTitle"
              name="jobTitle"
              label="Job Title"
              variant="standard"
              autoComplete="jobTitle"
              value={formik.values.jobTitle}
              onChange={formik.handleChange}
              error={formik.touched.jobTitle && Boolean(formik.errors.jobTitle)}
              helperText={formik.touched.jobTitle && formik.errors.jobTitle}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              fullWidth
              disabled={updateDisabled}>
              {updateText}
            </Button>
          </Grid>
        </Grid>
      </form>
    </Stack>
  );
};

export default SetDetails;
