import React from 'react';

import { Stack, useTheme } from '@mui/material';

const NotesPanel: React.FC = () => {

    const [notes, setNotes] = React.useState<string>("");

    const theme = useTheme();

    return (

        <Stack
            sx={{
                padding: 2,
            }}>
            <textarea
                placeholder='Notes...'
                cols={30}
                style={{
                    display: "flex",
                    minHeight: "85vh",
                    width: "100%",
                    border: "none",
                    outline: `1px solid ${theme.palette.primary.main}`,
                    resize: "none",
                    borderRadius: "5px",
                    padding: "5px",
                    color: theme.palette.text.primary
                }}
                value={notes}
                onChange={(event) => setNotes(event.target.value)} />
        </Stack>
    );
}

export default NotesPanel;