import { useEffect, useState } from 'react';

import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';

import { isOptionActive, useAudioInputs, useMeetingManager, useMeetingStatus, useToggleLocalMute } from 'amazon-chime-sdk-component-library-react';

import ControlButton from './control-button';
import TelehealthPopover, { CustomPopOverItemProps } from '../telehealth-popover';
import { Divider } from '@mui/material';

interface MuteProps {
    startMuted: boolean;
    disabled?: boolean;
    showText?: boolean;
    showDropDown?: boolean;
}

const LabelledMuteButton: React.FC<MuteProps> = ({
    startMuted,
    disabled = false,
    showText,
    showDropDown = true
}) => {

    const meetingManager = useMeetingManager();
    const { muted, toggleMute } = useToggleLocalMute();
    const { devices, selectedDevice } = useAudioInputs();
    const [dropdownOptions, setDropdownOptions] = useState<CustomPopOverItemProps[]>([]);

    const status = useMeetingStatus();

    useEffect(() => {
        if (status != 1) {
            console.log("Not connected");
            return;
        }

        if (muted !== startMuted) {
            toggleMute();
            console.log("Connected");
        }

        console.log("Expected" + startMuted + " Actual" + muted)


    }, [startMuted, status]);

    const selectOption = (option: CustomPopOverItemProps) => {
        handleClick(option.id);
    }

    const handleClick = async (deviceId: string): Promise<void> => {
        try {
            await meetingManager.startAudioInputDevice(deviceId);
        } catch (error) {
            console.error('AudioInputControl failed to select audio input device');
        }
    };

    useEffect(() => {
        const getDropdownOptions = async (): Promise<void> => {
            const deviceList: CustomPopOverItemProps[] = [];

            devices.map(async (device) => {
                const popoverItem: CustomPopOverItemProps = {
                    label: device.label,
                    id: device.deviceId,
                    active: await isOptionActive(device, device.deviceId)
                };

                deviceList.push(popoverItem);
            });

            setDropdownOptions(deviceList);
        };

        getDropdownOptions();
    }, [
        devices,
        selectedDevice,
        meetingManager,
        meetingManager.startAudioInputDevice,
    ]);

    return (
        <ControlButton activeIcon={<MicIcon color='action' />}
            inactiveIcon={<MicOffIcon color='action' />}
            active={!muted}
            onClick={toggleMute}
            showText={showText}
            disabled={disabled}
            activeLabel={'Mute Myself'}
            inactiveLabel={'Unmute Myself'} >
            <Divider orientation="vertical" />
            {
                showDropDown &&
                <TelehealthPopover
                    options={dropdownOptions}
                    openPopOverLabel='Select Microphone'
                    selectedOption={selectedDevice?.toString() || ""}
                    setSelectedOption={selectOption} />
            }
        </ControlButton >
    );
};

export default LabelledMuteButton;