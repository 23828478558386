import { Divider, Stack } from "@mui/material"
import UploadImageModal from "../upload/upload-image-modal"
import SelectCameraPopover from "./select-camera-popover"

interface VideoUploadButtonProps {
    uploadComplete: () => void
}

const VideoUploadButton: React.FC<VideoUploadButtonProps> = ({
    uploadComplete
}) => {
    return (
        <Stack direction={"row"}
            spacing={2}
            alignContent={"center"}
            justifyContent={"center"}
            sx={{
                width: "100%"
            }}
        >
            <UploadImageModal
                capture={true}
                reportImageUploaded={uploadComplete}
                restartVideo={false}>
                <Divider orientation="vertical" />
                <SelectCameraPopover startWithVideo={true} cameraType="back" />
            </UploadImageModal>
        </Stack>
    )
}

export default VideoUploadButton