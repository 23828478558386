import React, { useEffect } from "react";
import { useContext } from "react";

import { useRosterState } from "amazon-chime-sdk-component-library-react";

import { MeetingInfoContext } from "../../context/meeting-info-context";

import SelectedVideoTileGrid from "./selected-video-grid";

interface ExcludeSelfVideoTileGridProps {
  viewHeight: number;
  viewWidth: number;
  showNamePlate: boolean;
}

export const ExcludeSelfVideoTileGrid: React.FC<ExcludeSelfVideoTileGridProps> = ({
  viewHeight,
  viewWidth,
  showNamePlate,
}) => {

  const { roster } = useRosterState();
  const { selectedAttendees, setSelectedAttendees, userId, otherAttendees, setOtherAttendees } = useContext(MeetingInfoContext);

  useEffect(() => {
    const newOtherAttendees: string[] = [];
    const newToSetOtherAttendees: string[] = [];

    for (const attendeeId in roster) {

      if (!roster[attendeeId]) {
        continue;
      }

      if (!roster[attendeeId].externalUserId) {
        continue;
      }

      const currentAttendee: any = roster[attendeeId];

      let id = currentAttendee?.actualUserId;

      if (!id) {
        continue;
      }

      if (id == userId) {
        continue;
      }

      newOtherAttendees.push(attendeeId);

      if (!selectedAttendees.includes(attendeeId) && otherAttendees.includes(attendeeId)) {
        continue;
      }

      newToSetOtherAttendees.push(attendeeId);
    }

    if (!twoListsAreTheSame(newOtherAttendees, otherAttendees)) {
      setOtherAttendees(newOtherAttendees);
    }

    if (!twoListsAreTheSame(newToSetOtherAttendees, selectedAttendees)) {
      setSelectedAttendees(newToSetOtherAttendees);
    }

  }, [roster, userId, selectedAttendees]);

  const twoListsAreTheSame = (first: string[], second: string[]) => {
    if (first.length != second.length) {
      return false;
    }

    for (let i = 0; i < first.length; i++) {
      if (first[i] != second[i]) {
        return false;
      }
    }

    return true;
  }

  return (
    <SelectedVideoTileGrid
      tilesToShow={selectedAttendees}
      showNamePlate={showNamePlate}
      viewHeight={viewHeight}
      viewWidth={viewWidth} />
  );
};

export default ExcludeSelfVideoTileGrid;