import { auth } from "aws-iot-device-sdk-v2";

export class telehealthStaticCredentialProvider implements auth.AwsCredentialsProvider {

    private accessKeyId: string;
    private secretAccessKey: string;
    private sessionToken: string;
    private Region: string;

    constructor(accessKeyId: string, secret: string, sessionToken: string, region: string) {
        this.accessKeyId = accessKeyId;
        this.secretAccessKey = secret;
        this.sessionToken = sessionToken;
        this.Region = region;
    }

    getCredentials(): any {
        return {
            aws_access_id: this.accessKeyId,
            aws_secret_key: this.secretAccessKey,
            aws_sts_token: this.sessionToken,
            aws_region: this.Region
        }
    }

    async refreshCredentials() {
        console.log('Refreshing credentials');
    }
}