import React, { PropsWithChildren } from 'react';

import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import HelpIcon from '@mui/icons-material/Help';

import { User } from '../../types';

import OptionsDropdown from './options-dropdown';
import NavigationMenuItem from '../navigation-menu-item';
import LogoutButton from '../login/logout-button';

interface CalendarOptionsDropdownProps {
    page: string
    currentUser?: User,
}

export const CalendarOptionsDropdown: React.FC<PropsWithChildren<CalendarOptionsDropdownProps>> = ({
    page,
    currentUser,
    children
}) => {

    return (
        <OptionsDropdown
            dropdownName="calendar"
            displayedName={currentUser?.fullName || ""}>
            {page !== 'account' &&
                <NavigationMenuItem
                    label={"Account Settings"}
                    icon={<AccountCircleIcon />}
                    navigationPath={'/account'}
                />}
            {page !== 'help' &&
                <NavigationMenuItem
                    label={"Help"}
                    icon={<HelpIcon />}
                    navigationPath={'/help'}
                />}
            {children}
            <LogoutButton />
        </OptionsDropdown>
    );
};

export default CalendarOptionsDropdown;