import { SelectChangeEvent } from "@mui/material";
import UpdateTypeSelect from "../update-type-select";
import DictionaryAutocomplete from "../dictionary-autocomplete";


interface PatientSelectProps {
    selectedPatientId: string;
    patientIdList: string[];
    onChange: (selected: string[]) => void;
    disabled?: boolean;
}

const PatientSelect: React.FC<PatientSelectProps> = ({
    selectedPatientId,
    patientIdList,
    onChange,
    disabled,
}: PatientSelectProps) => {

    const patientIdDictionary: Record<string, string> = {};

    patientIdList.sort();

    for (let i = 0; i < patientIdList.length; i++) {
        patientIdDictionary[patientIdList[i]] = patientIdList[i];
    }

    return (
        <DictionaryAutocomplete
            id="patient-select"
            selectName="Patient"
            selected={selectedPatientId?.length > 0 ? [selectedPatientId] : []}
            options={patientIdList}
            names={patientIdDictionary}
            onChange={onChange}
            disabled={disabled}
            multiple={false}
        />
    )
}

export default PatientSelect;