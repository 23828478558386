import React from "react";

import { Stack, Typography, useTheme } from "@mui/material";
import SettingsIcon from '@mui/icons-material/Settings';

import { useAdminDeleteUser, useGetUsersWithIds, useUpdateOrganisation } from "../../store";
import { Organisation, User, UserType } from "../../types";

import UserTable from "./user-table";
import AddUserModal from "./add-user-modal";
import ConfirmationModal from "../confirmation-modal";
import EditOrganisationModal from "./edit-organisation-modal";
import TelehealthStyledButton from "../telehealth-styled-button";
import toast from "react-hot-toast";
import TransferUserOrganisationModal from "./transfer-user-organisation-modal";

interface EditOrganisationPageProps {
    finalOrganisation: Organisation;
}


const EditOrganisationPage: React.FC<EditOrganisationPageProps> = ({
    finalOrganisation,
}) => {

    const [addUserOpen, setAddUserOpen] = React.useState<boolean>(false);
    const [temporaryUser, setTemporaryUser] = React.useState<User | undefined>(undefined);
    const [editOrganisationOpen, setEditOrganisationOpen] = React.useState<boolean>(false);
    const [confirmationOpen, setConfirmationOpen] = React.useState<boolean>(false);
    const [selectedUser, setSelectedUser] = React.useState<User | null>(null);
    const [selectedTransferUser, setSelectedTransferUser] = React.useState<User | null>(null);

    const onUpdateSuccess = () => {
        toast.success("Organisation updated successfully");
    }

    const onUpdateError = (err: any) => {
        console.log(err);
        toast.error("Failed to update organisation");
    }

    const onDeleteSuccess = () => {
        toast.success("User deleted successfully");
    }

    const onDeleteError = (err: any) => {
        console.log(err);
        toast.error("Failed to delete user");
    }

    const { data: organisationUsers } = useGetUsersWithIds(finalOrganisation?.usersIds || [], false);
    const { mutate: updateOrganisation } = useUpdateOrganisation(onUpdateSuccess, onUpdateError);
    const { mutate: adminDeleteUser } = useAdminDeleteUser(onDeleteSuccess, onDeleteError);

    const addButton = () => {
        setAddUserOpen(true);
    }

    const openEditOrganisation = () => {
        setEditOrganisationOpen(true);
    }

    const closeEditOrganisation = () => {
        setEditOrganisationOpen(false);
    }

    const closeAddUser = () => {
        setTemporaryUser(undefined);
        setAddUserOpen(false);
    }

    const deleteUser = (user: User) => {
        setSelectedUser(user);
        setConfirmationOpen(true);
    }

    const deleteUserClick = () => {
        adminDeleteUser(selectedUser?.userId || "");
        setConfirmationOpen(false);
    }

    const closeUserConfirmation = () => {
        setConfirmationOpen(false);
    }

    const convertTemporaryUser = (user: User) => {
        setTemporaryUser(user);
        setAddUserOpen(true);
    }

    const openTransferUser = (user: User) => {
        setSelectedTransferUser(user);
        console.log("Transfer user", user);
    }

    const closeTransferUser = () => {
        setSelectedTransferUser(null);
    }

    // get normal color of text form mui theme

    const theme = useTheme();

    const normalTextColor = theme.palette.text.primary;

    return (
        <Stack
            direction={"column"}
            spacing={2}
            alignItems={"left"}>
            <Stack
                direction={"row"}
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={2}
                width={"100%"}>
                <Typography variant="h3" color={normalTextColor}>
                    Organisation
                </Typography>
                <Stack
                    direction={"row"}
                    spacing={2}
                    justifyItems={"center"}
                    height={"100%"}>
                    <TelehealthStyledButton
                        onClick={openEditOrganisation}
                        label="Organisation Settings"
                        variant="contained"
                        showText={true}
                        icon={
                            <SettingsIcon />
                        }
                    />
                </Stack>
            </Stack>
            {organisationUsers &&
                <UserTable
                    users={organisationUsers}
                    addButtonClick={addButton}
                    convertTemporaryUser={convertTemporaryUser}
                    deleteUser={deleteUser}
                    transferUser={openTransferUser} />
            }
            <AddUserModal
                open={addUserOpen}
                user={temporaryUser}
                organisationId={finalOrganisation?.organisationId || ""}
                onClose={closeAddUser} />
            <EditOrganisationModal
                open={editOrganisationOpen}
                onClose={closeEditOrganisation}
                organisation={finalOrganisation}
                updateOrganisation={updateOrganisation}
                create={false} />
            <ConfirmationModal
                open={confirmationOpen}
                accept={deleteUserClick}
                onClose={closeUserConfirmation}
                messages={["Are you sure you want to delete user?", "It cannot be undone."]} />
            <TransferUserOrganisationModal
                open={selectedTransferUser !== null}
                onClose={closeTransferUser}
                user={selectedTransferUser || undefined} />
        </Stack>
    )
}

export default EditOrganisationPage;