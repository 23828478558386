import { Stack, Typography, useTheme } from "@mui/material";
import StraightenIcon from '@mui/icons-material/Straighten';
import CenterFocusStrongIcon from '@mui/icons-material/CenterFocusStrong';

import ControlButton from "../meeting/control-button";


interface ModelControlPanelProps {
    showMeasureTool: boolean;
    onMeasureToolToggle: () => void;
    distance: number;
    angle: number;
    onCenterModel: () => void;
}

const ModelControlPanel: React.FC<ModelControlPanelProps> = ({
    showMeasureTool,
    onMeasureToolToggle,
    distance,
    angle,
    onCenterModel,
}: ModelControlPanelProps) => {

    const distanceLabel = distance < 0 ? "Click to measure points" : `Distance: ${distance.toFixed(2)} cm, Angle: ${angle.toFixed(2)} degrees`;
    const controlLabel = "Left click and drag to rotate, right click and drag to pan, scroll to zoom";

    const theme = useTheme();

    return (
        <Stack
            direction={"column"}
            spacing={1}
            width={"fit-content"}
            alignItems={"center"}
            alignContent={"center"}>
            <Stack
                direction={"row"}
                spacing={1}
                width={"fit-content"}
                alignItems={"center"}
                alignContent={"center"}>
                <ControlButton
                    onClick={onCenterModel}
                    showText={false}
                    active={false}
                    activeColor={theme.palette.primary.light}
                    activeLabel="Center Model"
                    inactiveLabel="Center Model"
                    inactiveIcon={<CenterFocusStrongIcon />} />
                <ControlButton
                    onClick={onMeasureToolToggle}
                    showText={false}
                    active={showMeasureTool}
                    activeColor={theme.palette.primary.light}
                    activeLabel="Turn off Measure Tool"
                    inactiveLabel="Turn on Measure Tool"
                    inactiveIcon={<StraightenIcon />}
                    activeIcon={<StraightenIcon />} />
            </Stack>
            <Typography variant="h6" color="primary">
                {showMeasureTool ? distanceLabel : controlLabel}
            </Typography>
        </Stack>
    );
};

export default ModelControlPanel;