import React from "react";

import { Stack, TableCell, Typography } from "@mui/material";
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import { Organisation, UserType } from "../../types";

import { useGetUsersWithIds } from "../../store";
import { useNavigate } from "react-router-dom";
import TelehealthIconButton from "../telehealth-icon-button";

interface OrganisationTableRowProps {
    deleteOrganisation: (organisation: Organisation) => void;
    organisation: Organisation;
    canEdit: boolean;
    canDelete: boolean;
}

const OrganisationTableRow: React.FC<OrganisationTableRowProps> = ({
    deleteOrganisation,
    organisation,
    canEdit,
    canDelete
}) => {

    const { data: users } = useGetUsersWithIds(organisation.usersIds, false);

    let admins = "";

    if (users) {
        admins = users.filter(
            user => {
                if (!user) {
                    return false;
                }

                return user.userType === UserType.SysAdmin || user.userType === UserType.OrgAdmin
            }
        ).map(user => {

            if (!user) {
                return "";
            }

            return user.fullName
        }).join(", ");
    }

    if (!users || users && users.length > 0) {
        canDelete = false;
    }

    const navigate = useNavigate();

    const navigateToPath = (navigationPath: string) => {
        navigate(navigationPath)
    }

    return (
        <>
            <TableCell align="left">
                <Typography variant="body1" fontSize={16}>
                    {organisation.name}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <Typography variant="body1" fontSize={16}>
                    {organisation.type}
                </Typography>
            </TableCell>
            <TableCell align="left">
                <div style={{ width: "20px", height: "20px", backgroundColor: organisation.color, borderRadius: "0.1rem" }} />
            </TableCell>
            <TableCell align="left">
                <Typography variant="body1" fontSize={16}>
                    {admins}
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Stack
                    direction="row"
                    justifyContent={"right"}>
                    <TelehealthIconButton
                        icon={<EditIcon />}
                        color="info"
                        tooltip="Edit Organisation"
                        inactiveTooltip="You do not have permission to edit this organisation"
                        disabled={!canEdit}
                        onClick={() => navigateToPath(`/organisation?organisationId=${organisation.organisationId}`)} />
                    <TelehealthIconButton
                        icon={<DeleteForeverIcon />}
                        color="error"
                        tooltip="Delete Organisation"
                        inactiveTooltip="You do not have permission to delete this organisation"
                        disabled={!canDelete}
                        onClick={() => deleteOrganisation(organisation)} />
                </Stack>
            </TableCell>
        </>
    )
}

export default OrganisationTableRow;