import * as React from "react";

import { useFormik } from "formik";
import * as Yup from "yup";

import { Alert, Button, Grid, TextField, Typography } from "@mui/material";

interface ChangePasswordProps {
  updatePassword: (oldPassword: string, newPassword: string) => void;
}

const ChangePassword: React.FC<ChangePasswordProps> = ({
  updatePassword,
}) => {
  const [submissionError, setSubmissionError] = React.useState<string>("");

  const formik = useFormik({
    initialValues: {
      originalPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      originalPassword:
        Yup.string().required("Password is required"),
      newPassword: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("newPassword")], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      const { originalPassword, newPassword, } = values;

      try {
        updatePassword(originalPassword, newPassword);
      } catch (error: any) {
        console.log(error);
        setSubmissionError(error);
      }
    },
  });

  const canSubmit = formik.dirty && formik.isValid;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography component="h6" variant="h6">
            Please enter the details below to update your password.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="originalPassword"
            name="originalPassword"
            label="Current Password"
            type="password"
            variant="outlined"
            value={formik.values.originalPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.originalPassword && Boolean(formik.errors.originalPassword)}
            helperText={formik.touched.originalPassword && formik.errors.originalPassword}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="newPassword"
            name="newPassword"
            label="New Password"
            type="password"
            variant="outlined"
            value={formik.values.newPassword}
            onChange={formik.handleChange}
            error={formik.touched.newPassword && Boolean(formik.errors.newPassword)}
            helperText={formik.touched.newPassword && formik.errors.newPassword}
            onBlur={formik.handleBlur}
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            fullWidth
            id="confirmPassword"
            name="confirmPassword"
            label="Confirm New Password"
            type="password"
            variant="outlined"
            value={formik.values.confirmPassword}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={
              formik.touched.confirmPassword &&
              Boolean(formik.errors.confirmPassword)
            }
            helperText={
              formik.touched.confirmPassword && formik.errors.confirmPassword
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={!canSubmit}
            fullWidth>
            Update Details
          </Button>
        </Grid>
        {submissionError && (
          <Grid item xs={12}>
            <Alert variant="outlined" severity="error">
              {submissionError}
            </Alert>
          </Grid>
        )}
      </Grid>
    </form >
  );
};

export default ChangePassword;
