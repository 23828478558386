export const createCss = (videoHeight: number, videoWidth: number, viewHeight: number, viewWidth: number, tiles: number) => {

  const isPortrait = viewHeight > viewWidth;

  return {
    maxWidth: getWidth(viewWidth, tiles),
    maxHeight: getHeight(viewHeight, tiles),
    height: isPortrait ? getHeight(viewHeight, tiles) : "auto",
    width: isPortrait ? "auto" : getWidth(viewWidth, tiles),
  }
}

const getHeight = (viewHeight: number, tiles: number) => {
  return calculateSize(viewHeight, tiles) + "vh";
}

const getWidth = (viewWidth: number, tiles: number) => {
  return calculateSize(viewWidth, tiles) + "vw";
}

const calculateSize = (size: number, tiles: number) => {
  const squareRoot = Math.ceil(Math.sqrt(tiles));

  const viewSize = Math.round(size / squareRoot);

  return viewSize;
}