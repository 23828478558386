import { Stack, Typography } from "@mui/material";

interface NoViewProps {
    message: string
    backgroundColor?: string
}


const NoRemoteView: React.FC<NoViewProps> = ({
    message,
    backgroundColor = "white"
}: NoViewProps) => {


    return (
        < Stack
            justifyContent="center"
            alignItems="center"
            padding={2}
            sx={{
                backgroundColor: backgroundColor,
                borderRadius: 2,
                width: "fit-content",
                height: "fit-content",
            }}>
            <Typography variant="h3" sx={{
            }}>
                {message}
            </Typography>
        </Stack >
    );
}

export default NoRemoteView;