import React from "react";
import { createContext } from "react";

interface Props {
    children: React.ReactNode;
}

export type WindowSizeContextProps = {
    width: number;
    height: number;
    isPortrait: boolean;
};

export const WindowSizeContext = createContext({
    width: 0,
    height: 0,
    isPortrait: false,
});

export const WindowSizeProvider: React.FC<Props> = ({ children }) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);
    const [isPortrait, setIsPortrait] = React.useState(window.innerHeight > window.innerWidth);

    React.useEffect(() => {
        const handleResize = () => {
            setWidth(window.innerWidth);
            setHeight(window.innerHeight);
            setIsPortrait(window.innerHeight > window.innerWidth);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <WindowSizeContext.Provider value={{ width, height, isPortrait }}>
            {children}
        </WindowSizeContext.Provider>
    );
}