import React from "react"

import { Button, Card, Container, Stack, Typography } from "@mui/material"

import { useGetCurrentUser } from "../store"

import NavigationTelehealthToolbar from "../components/navigation-telehealth-toolbar"

import ARTelehealthManualv10 from "../assets/ARTelehealthManualv10.pdf"
import ConnectIoT from "../websocket/connect-iot"

const HelpPage: React.FC = () => {

    const { data: currentUser } = useGetCurrentUser()

    const downloadRef = React.useRef<HTMLAnchorElement>(null)

    const downloadFile = () => {
        if (downloadRef.current) {
            downloadRef.current.click()
        }
    }

    return (
        <Stack
            justifyContent="top"
            alignItems="center"
            spacing={5}
            sx={{
                minHeight: "100vh",
                backgroundColor: "#F5F5F5",
            }}>
            <NavigationTelehealthToolbar page="help" currentUser={currentUser} />
            <Container>
                <Card>
                    <Stack padding={2} spacing={4}>
                        <Stack spacing={2}>
                            <Typography variant="h2">Manual</Typography>
                            <Typography variant="body1">
                                {`You can download the manual here: `}
                            </Typography>
                            <Button
                                variant={"contained"}
                                onClick={downloadFile}
                                sx={{
                                    width: "fit-content",
                                }}>
                                Download Manual
                            </Button>
                            <a
                                href={ARTelehealthManualv10}
                                ref={downloadRef} download style={{
                                    display: "none",
                                }} />
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="h2">Headset Help Articles</Typography>
                            <Typography variant="body1">
                                {`Useful articles below: `}
                            </Typography>
                            <Button variant={"contained"} sx={{
                                width: "fit-content",
                            }} onClick={() => { window.open("https://support.realwear.com/knowledge/voice-commands") }}>
                                RealWare Voice Commands
                            </Button>
                            <Button variant={"contained"} sx={{
                                width: "fit-content",
                            }} onClick={() => { window.open("https://support.realwear.com/knowledge/determining-eye-dominance") }}>
                                RealWare Eye Dominance
                            </Button>
                        </Stack>
                        <Stack spacing={2}>
                            <Typography variant="h2">Common Issue Trouble Shooting</Typography>
                            <Typography variant="h4">
                                {`I can't remember what the commands are:`}
                            </Typography>
                            <Typography variant="body1">
                                {`The website commands are shown on the labelled buttons on screen. Reading them out loud will activate the commands. Otherwise you can say "Select Item" and the number next to the label. Link to global commands is above.`}
                            </Typography>
                            <Typography variant="h4">
                                {`The power light is blinking:`}
                            </Typography>
                            <Typography variant="body1">
                                {`The power light may blink either green, orange or red to indicate how full the battery is.`}
                            </Typography>
                            <Typography variant="h4">
                                {`I can't tell if the device is on:`}
                            </Typography>
                            <Typography variant="body1">
                                {`To turn on the device you need to hold down the power button. If the volume is not muted it will make a sound on startup and shut down. By default, the call volume is muted in the consultation so there is no reason to mute the headset’s audio. If you are unsure if the device is on, put the headset on and tap the power button. If it is on it the screen will turn on.`}
                            </Typography>
                            <Typography variant="h4">
                                {`There is a keyboard dialogue:`}
                            </Typography>
                            <Typography variant="body1">
                                {`It is possible to select the navigation bar of the website allowing inputting of a web address. Say “Navigate Back” to go to the previous page. If that does not bring you to the call you can say “Navigate Home.” to return the home menu, if you do see the next section “Navigating home without ending meeting.”

                        To prevent accidental commands, use the voice command “Mute Microphone” which will stop voice commands until the action button (large square button on the camera side of the headset).`}
                            </Typography>
                            <Typography variant="h4">
                                {`I am on a weird page:`}
                            </Typography>
                            <Typography variant="body1">
                                {` If the command “Navigate Back” does not bring you to the call, you can say “Navigate Home” to return to the home menu. If you do see the section “Navigating home without ending meeting” to prevent issues with the camera being enabled.`}
                            </Typography>
                            <Typography variant="h4">
                                {`I Navigated home without ending meeting:`}
                            </Typography>
                            <Typography variant="body1">
                                {`If you navigate home without using the “Leave Call” command, you will just suspend the meeting and not end it. If you do return to the home menu and say, “My Programs” and then “Firefox” to return to the call.`}
                            </Typography>
                            <Typography variant="h4">
                                {`I Navigated back or home while taking a photo`}
                            </Typography>
                            <Typography variant="body1">
                                {`If you navigate from the camera view without taking a photo the video will not automatically be turned back on. Say “Enable Camera” to reactivate the video.`}
                            </Typography>
                            <Typography variant="h4">
                                {`The video for the headset is black or not appearing:`}
                            </Typography>
                            <Typography variant="body1">
                                {`If the video for call is black the cause is that this call or a previous call has been suspended instead of being exited. Try “Disable Camera” and then “Enable Camera”. If this does not work say “Leave Call” and then follow the instructions to rejoin the call.`}
                            </Typography>
                            <Typography variant="h4">
                                {`What is the difference between Mute Myself and Mute Microphone?`}
                            </Typography>
                            <Typography variant="body1">
                                {`“Mute Microphone” stops the headset listening to commands, it has no effect on whether the call sends your voice to the other user.

                        “Mute Myself” mutes your voice in the call and prevents other users from hearing you. By default your voice is muted as it is intended that the microphone in the speaker system to act as a microphone for you and the patient.

                        It is recommended that you say “Mute Microphone” when you start a call to prevent accidental voice commands. The “Mute Myself” command will be rarely used since the microphone is muted by default.`}
                            </Typography>
                        </Stack>
                    </Stack>
                </Card>
            </Container>
        </Stack>
    )
}

export default HelpPage