import React from "react";
import { Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";

import { Organisation } from "../../types";

import CancelButton from "../cancel-button";
import EditOrganisationForm from "./edit-organisation-form";

interface EditOrganisationModalProps {
    open: boolean;
    onClose: () => void;
    updateOrganisation: (organisation: Organisation) => void;
    organisation: Organisation;
    create: boolean;
}

const EditOrganisationModal: React.FC<EditOrganisationModalProps> = ({
    open,
    onClose,
    organisation,
    updateOrganisation,
    create,
}) => {

    const closeModal = () => {
        onClose();
    }

    const onUpdate = (updated: Organisation) => {
        updateOrganisation(updated);
        closeModal();
    }

    // form component is required for formik to work
    return (
        <Dialog open={open} onClose={closeModal} maxWidth={"md"}>
            <Stack spacing={2}>
                <DialogTitle component={"div"}>
                    <Typography variant="h3">
                        {create ? "Add Organisation" : "Edit Organisation"}
                    </Typography>
                    <CancelButton handleClose={closeModal} />
                </DialogTitle>
                <DialogContent>
                    <EditOrganisationForm
                        updateOrganisation={onUpdate}
                        organisation={organisation}
                        create={create} />
                </DialogContent>
            </Stack>
        </Dialog >
    )
}

export default EditOrganisationModal;