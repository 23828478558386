import React from "react";

import { useRosterState } from "amazon-chime-sdk-component-library-react";

import { MeetingInfoContext } from "../../context/meeting-info-context";
import VisibilitySelect, { VisibilityDictionary } from "./visibility-select";
import { AuthContext } from "../../context/auth-context";
import { useGetUsersWithIds } from "../../store";
import { User } from "../../types";


const AttendeeSelect: React.FC = () => {

    const { roster } = useRosterState();
    const { otherAttendees, selectedAttendees, setSelectedAttendees } = React.useContext(MeetingInfoContext);
    const { isAuthenticated } = React.useContext(AuthContext);

    let users: { [key: string]: User } = {}

    let actualIds = []

    if (isAuthenticated) {
        for (let i = 0; i < otherAttendees.length; i++) {
            const id = otherAttendees[i];

            if (!roster[id]) {
                continue;
            }

            const actualId = (roster[id] as any).actualUserId

            if (actualId) {
                actualIds.push(actualId);
            }
        }
    }

    const { data } = useGetUsersWithIds(actualIds, false);

    if (data) {
        for (let i = 0; i < data.length; i++) {

            const currentUser = data[i];

            if (!currentUser || currentUser == null) {
                continue;
            }

            users[currentUser.userId] = currentUser;
        }
    }

    const selectedAttendeeName = (id: string) => {
        if (!roster[id]) {
            return {
                name: "",
                type: "",
                title: "",
            }
        }

        const name = roster[id].name;

        if (!name) {
            return {
                name: "",
                type: "",
                title: "",
            }
        }

        const user = roster[id] as any;

        const actualId = (roster[id] as any).actualUserId || ""
        let title = ""
        if (users[actualId]) {
            title = users[actualId].jobTitle || "";
        }

        let type = ""
        if (user?.userType && user?.userType.length > 0) {
            type = user?.userType;
        }

        return {
            name,
            type,
            title,
        }
    }

    const attendeeNameDictionary: VisibilityDictionary = {};

    Object.keys(roster).forEach((id) => {
        attendeeNameDictionary[id] = selectedAttendeeName(id);
    });

    return (
        <VisibilitySelect
            id={"attendee-select"}
            selectType={"Attendee"}
            selected={selectedAttendees}
            ids={otherAttendees}
            dictionary={attendeeNameDictionary}
            handleChange={setSelectedAttendees}
        />
    );
}

export default AttendeeSelect;