import { Card, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";

import { Organisation } from "../../types";
import OrganisationTableRow from "./organisation-table-row";

interface OrganisationTableProps {
    organisations: Organisation[];
    showEditButton?: boolean;
    showDeleteButton?: boolean;
    deleteOrganisation: (organisation: Organisation) => void;
}

const OrganisationTable: React.FC<OrganisationTableProps> = ({
    organisations,
    showEditButton,
    showDeleteButton,
    deleteOrganisation
}) => {

    return (
        <Card sx={{
            width: "95%",
            height: "95%",
            padding: 2,
        }}>
            <TableContainer component={Stack}>
                <Table
                    sx={{ width: "100%" }}
                    aria-label="organisation table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Name
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Type
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Colour
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Admins
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body1" fontSize={16}>
                                    Actions
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {organisations.map((organisation) => {
                            return (
                                <TableRow
                                    key={organisation.organisationId}>
                                    <OrganisationTableRow
                                        canEdit={showEditButton || false}
                                        canDelete={showDeleteButton || false}
                                        deleteOrganisation={deleteOrganisation}
                                        organisation={organisation} />
                                </TableRow>
                            )
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    )
}

export default OrganisationTable;