import { useEffect, useRef } from "react";
import { Line, Vector2, Vector3 } from "three";
import { extend } from "@react-three/fiber";

import { Line2, LineGeometry, LineMaterial } from 'three-stdlib';

extend({ Line });

interface PointsLineProps {
    points: Vector3[];
    visible?: boolean;
}

const PointsLine = ({
    points,
    visible = true
}: PointsLineProps) => {
    const lineRef = useRef<Line2>(new Line2());

    const firstPointVisible = points.length > 0 && visible;
    const secondPointVisible = points.length > 1 && visible;
    const firstPoint = points.length > 0 ? points[0] : new Vector3();
    const secondPoint = points.length > 1 ? points[1] : new Vector3();

    const geometry = new LineGeometry();

    const material = new LineMaterial({
        color: 0x00ff00,
        linewidth: 5,
        resolution: new Vector2(window.innerWidth, window.innerHeight),
    });

    useEffect(() => {
        if (points.length < 2) {
            return;
        }

        if (!lineRef.current) {
            return;
        }

        const positions: number[] = [];
        points.forEach(point => {
            positions.push(point.x, point.y, point.z);
        });

        geometry.setPositions(positions);

        lineRef.current.geometry = geometry;
        lineRef.current.material = material;

    }, [points]);

    return (
        <>
            <primitive object={lineRef.current} ref={lineRef} visible={secondPointVisible} />
            <mesh position={firstPoint} visible={firstPointVisible}>
                <sphereGeometry args={[0.2, 36, 36]} />
                <meshBasicMaterial color="green" />
            </mesh>
            <mesh position={secondPoint} visible={secondPointVisible}>
                <sphereGeometry args={[0.2, 36, 36]} />
                <meshBasicMaterial color="green" />
            </mesh>
        </>
    );
};

export default PointsLine;