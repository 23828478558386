import React from "react";

import { Meeting, MeetingJoinInfo, Organisation, User } from "../../types"

import toast from "react-hot-toast";

import { useUpdateAttendees } from "../../store";

import EditAttendees from "./edit-attendees";
import OrganisationTag from "./organisation-tag";
import UserTagList from "./user-tag-list";
import EditOrganisationButtons from "./edit-organisation-buttons";

interface EditOrganisationTagProps {
    organisation?: Organisation
    joinInfo?: MeetingJoinInfo
    meeting: Meeting
    invitedUsers: User[]
    joinedUsers: string[]
    canJoin: boolean
    canEdit: boolean
}

const EditOrganisationTag: React.FC<EditOrganisationTagProps> = ({
    organisation,
    joinInfo,
    meeting,
    invitedUsers,
    joinedUsers,
    canEdit,
    canJoin,
}) => {

    const [edit, setEdit] = React.useState(false)
    const [attendees, setAttendees] = React.useState<string[]>(meeting.attendeesId)
    const [waiting, setWaiting] = React.useState<boolean>(false)

    const onSuccess = () => {
        toast.success("Attendees updated")
        setWaiting(false)
        setEdit(false)
    }

    const onError = () => {
        toast.error("Failed to update attendees")
        setWaiting(false)
        setEdit(false)
    }

    const { mutate: updateAttendees } = useUpdateAttendees(onSuccess, onError)

    const onSubmit = () => {
        setWaiting(true)
        updateAttendees({
            meetingId: meeting.meetingId,
            attendeesId: attendees,
        })
    }

    const onClick = () => {
        setEdit(!edit)
    }

    let canSubmit = attendees.length !== meeting.attendeesId.length
    // If attendee lists are the same length, check if they are the same
    if (!canSubmit) {
        for (const attendeeId of attendees) {
            if (!meeting.attendeesId.includes(attendeeId)) {
                canSubmit = true;
                break;
            }
        }
    }

    return (
        <OrganisationTag
            organisation={organisation}
            canJoin={canJoin}
            attendees={invitedUsers.length}
            buttons={canEdit ?
                <EditOrganisationButtons
                    waiting={waiting}
                    editInProgress={edit}
                    canSubmit={canSubmit}
                    onSubmit={onSubmit}
                    onEditClick={onClick} /> :
                undefined}>
            {!edit && <UserTagList
                meeting={meeting}
                organisation={organisation}
                invitedUsers={invitedUsers}
                joinedUsers={joinedUsers}
                canJoin={canJoin}
            />}
            {edit && <EditAttendees
                joinInfo={joinInfo}
                organisationIds={[organisation?.organisationId || ""]}
                meeting={meeting}
                attendees={attendees}
                waiting={waiting}
                updateAttendees={setAttendees}
            />}
        </OrganisationTag>
    )
}

export default EditOrganisationTag