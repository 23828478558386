import { useEffect, useState } from "react";

import toast from "react-hot-toast";

import { User } from "../../types";
import { useListOrganisations, useTransferUserOrganisation } from "../../store";

import SingleOrganisationSelect from "./single-organisation-select";
import TelehealthStyledButton from "../telehealth-styled-button";
import { Dialog, Stack, Typography } from "@mui/material";
import CancelButton from "../cancel-button";

interface TransferUserOrganisationModalProps {
    open: boolean;
    onClose: () => void;
    user?: User;
}

const TransferUserOrganisationModal: React.FC<TransferUserOrganisationModalProps> = ({
    open,
    onClose,
    user
}) => {

    useEffect(() => {
        setSelectedOrganisation(user?.organisationId || "");
    }, [user]);

    const [selectedOrganisation, setSelectedOrganisation] = useState<string>("");
    const [loading, setLoading] = useState<boolean>(false);

    const { data: organisations } = useListOrganisations()

    const organisationList = organisations || []

    const handleClose = () => {
        setLoading(false);
        onClose();
    }

    const onSuccess = () => {
        toast.success("User transferred to new organisation");
        setLoading(false);
    }

    const onFailure = (error: string) => {
        toast.error("Failed to transfer user to new organisation");
        console.log(error);
        setLoading(false);
    }

    const { mutate: transferUserOrganisation } = useTransferUserOrganisation(onSuccess, onFailure);

    const startTransfer = () => {

        if (!user) {
            return;
        }

        setLoading(true);
        transferUserOrganisation({ userId: user.userId, organisationId: selectedOrganisation });
        onClose();
    }

    const disabled = user?.organisationId === selectedOrganisation || !selectedOrganisation;

    return (
        <Dialog open={open} onClose={onClose}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                paddingX={5}
                paddingY={3}
                spacing={2}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <CancelButton handleClose={handleClose} />
                    <Typography variant="h4">Transfer User Organisation</Typography>
                </Stack>
                <SingleOrganisationSelect
                    organisationList={organisationList}
                    selected={selectedOrganisation}
                    onSelect={setSelectedOrganisation}
                    name={"Select Organisation"}
                    label={"Select Organisation"}
                />
                <TelehealthStyledButton
                    onClick={startTransfer}
                    disabled={loading || disabled}
                    showText
                    label={"Transfer User"} />
            </Stack>
        </Dialog >
    );
};

export default TransferUserOrganisationModal;