import { Button, Grid, TextField, Typography } from "@mui/material";
import { useFormik } from "formik";
import React from "react";
import * as Yup from "yup";

interface ForgotPasswordProps {
    forgotPassword: (email: string) => void;
    intialEmail: string;
}

const ForgotPasswordForm: React.FC<ForgotPasswordProps> = ({
    forgotPassword,
    intialEmail,
}) => {

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: intialEmail,
        },
        validationSchema: Yup.object({
            email: Yup.string()
                .email("Invalid email address")
                .required("Email is required"),
        }),
        onSubmit: (values) => {
            const { email } = values;

            forgotPassword(email);

            console.log("Forgot password form submitted");
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography component="h6" variant="h6">
                        Please enter your email to reset your password.
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        label="Email"
                        variant="outlined"
                        autoComplete="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained">
                        Submit
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

export default ForgotPasswordForm;