import React, { PropsWithChildren } from "react";

import { Dialog, Stack, SxProps, Theme, Typography } from "@mui/material"

import CancelButton from "./cancel-button";

interface ModelModalProps {
    open: boolean;
    title: string;
    onClose: () => void;
    maxWidth?: "xs" | "sm" | "md" | "lg" | "xl" | false;
    stackSX?: SxProps<Theme>;
}

const TelehealthDialogue: React.FC<PropsWithChildren<ModelModalProps>> = ({
    open,
    title,
    onClose,
    maxWidth = "lg",
    stackSX,
    children,
}) => {

    return (
        <Dialog open={open} onClose={onClose} fullWidth maxWidth={maxWidth}>
            <Stack
                direction="column"
                justifyContent="center"
                alignItems="center"
                minWidth={"20vw"}
                minHeight={"20vh"}
                padding={2}
                spacing={2}
                sx={stackSX}>
                <Stack direction="row" justifyContent="center" alignItems="center">
                    <Typography variant="h4">{title}</Typography>
                    <CancelButton handleClose={onClose} />
                </Stack>
                <Stack
                    spacing={2}
                    alignItems={"center"}
                    justifyContent={"center"}>
                    {children}
                </Stack>
            </Stack>
        </Dialog >);
}

export default TelehealthDialogue;