import React, { PropsWithChildren, useContext, useEffect } from "react";

import { useMeetingManager, } from "amazon-chime-sdk-component-library-react";
import { DataMessage } from "amazon-chime-sdk-js";

import toast from "react-hot-toast";

import { MeetingInfoContext } from "../../context/meeting-info-context";
import { MeetingJoinType } from "../../types";

interface ToastJoinProps {
    showJobTitle?: boolean;
}

const ToastJoin: React.FC<PropsWithChildren<ToastJoinProps>> = ({
    showJobTitle,
    children,
}) => {

    const { userName, jobTitle, userId, meetingType, connected } = useContext(MeetingInfoContext);

    const [listening, setListening] = React.useState(false);

    useEffect(() => {
        if (!meetingManager) {
            return;
        }

        if (!meetingManager.audioVideo) {
            return;
        }

        let sentUserName = userName;

        if (meetingType != MeetingJoinType.Metro && meetingType != MeetingJoinType.Remote) {
            sentUserName = `${userName} ${meetingType}`;
        }

        if (showJobTitle) {
            sentUserName = `(${jobTitle}) ${sentUserName}`;
        }

        const user = { userName: sentUserName, userId: userId };

        meetingManager.audioVideo.realtimeSendDataMessage("join", JSON.stringify(user));

        if (listening) {
            return;
        }

        meetingManager.audioVideo.realtimeSubscribeToReceiveDataMessage("join", (message: DataMessage) => {
            const user = JSON.parse(message.text());
            toast.success(`${user.userName} joined meeting`, { duration: 5000 });
        });

        setListening(true);

    }, [connected]);

    const meetingManager = useMeetingManager();

    return (
        <>{children}</>
    );
};

export default ToastJoin;
