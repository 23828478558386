import { PropsWithChildren } from "react";

import CallIcon from '@mui/icons-material/Call';
import CallEndIcon from '@mui/icons-material/CallEnd';

import { User } from "../../types"
import CustomTag from "../custom-tag";

interface AttendeeBoxProps {
    attendee: User
    joined: boolean
    canJoin: boolean
}

const AttendeeBox: React.FC<PropsWithChildren<AttendeeBoxProps>> = ({
    attendee,
    joined,
    canJoin,
    children
}) => {

    const tooltip = canJoin ?
        joined ? "In meeting." : "Can join meeting." :
        attendee.fullName

    const secondaryText = attendee.jobTitle && attendee.jobTitle.length > 0 ?
        `(${attendee.jobTitle})` : undefined

    return (
        <CustomTag
            text={attendee.fullName}
            secondaryText={secondaryText}
            color={"lightgray"}
            tooltip={tooltip}
            icon={canJoin ?
                joined ?
                    <CallIcon
                        color={"success"}
                        sx={{
                            width: "1.5rem",
                            height: "1.5rem"
                        }} />
                    :
                    <CallEndIcon
                        sx={{
                            width: "1.5rem",
                            height: "1.5rem"
                        }} />
                : undefined}
            sx={{
                minWidth: "150px"
            }}>
            {children}
        </CustomTag>
    )
}

export default AttendeeBox