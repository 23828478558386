import { Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { Patient } from "../../types";

import CancelButton from "../cancel-button";
import TelehealthIconButton from "../telehealth-icon-button";
import CustomTag from "../custom-tag";
import toast from "react-hot-toast";


interface PatientCreatedDialogueProps {
    patient: Patient;
    open: boolean;
    onClose: () => void;
}

const PatientCreatedDialogue: React.FC<PatientCreatedDialogueProps> = ({
    patient,
    open,
    onClose }) => {

    const handleCopy = async () => {
        try {
            await navigator.clipboard.writeText(patient.patientId);
            toast.success("Patient ID copied to clipboard");
        } catch (err) {
            console.error('Failed to copy Patient ID: ', err);
        }
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <Stack spacing={2} padding={2}>
                <DialogTitle component={Stack}>
                    <Typography variant="h4">
                        Patient Created
                    </Typography>
                    <CancelButton handleClose={onClose} />
                </DialogTitle>
                <DialogContent>
                    <CustomTag
                        color="#e6e6e6"
                        text={patient.patientId}
                    >
                        <TelehealthIconButton
                            onClick={handleCopy}
                            tooltip="Copy Patient ID"
                            icon={<ContentCopyIcon />}
                        />
                    </CustomTag>
                </DialogContent>
            </Stack>
        </Dialog>
    );
}

export default PatientCreatedDialogue;