import * as React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert, Button, Grid, TextField, Typography } from "@mui/material";

interface NewPasswordChallengeFormProps {
  completeNewPasswordChallenge: (password: string) => void;
  email: string;
}

const NewPasswordChallengeForm: React.FC<NewPasswordChallengeFormProps> = ({
  completeNewPasswordChallenge,
  email,
}) => {
  const [submissionError, setSubmissionError] = React.useState<string>("");

  const formik = useFormik({
    initialValues: {
      email: email,
      password: "",
      confirmPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/\d/, "Password must contain at least one number")
        .required("Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords must match")
        .required("Confirm Password is required"),
    }),
    onSubmit: (values) => {
      const { email, password } = values;

      try {
        completeNewPasswordChallenge(password);
      } catch (error: any) {
        console.log(error);
        setSubmissionError(error);
      }
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography component="h6" variant="h6">
              Please set your details below, and set a new password.
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              disabled
              fullWidth
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="confirmPassword"
              name="confirmPassword"
              label="Confirm Password"
              type="password"
              variant="outlined"
              value={formik.values.confirmPassword}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={
                formik.touched.confirmPassword &&
                Boolean(formik.errors.confirmPassword)
              }
              helperText={
                formik.touched.confirmPassword && formik.errors.confirmPassword
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Update Details
            </Button>
          </Grid>
          {submissionError && (
            <Grid item xs={12}>
              {submissionError === "User already exists" ? (
                <Alert variant="outlined" severity="warning">
                  {submissionError}. Please <a href="/login">login</a> instead.
                </Alert>
              ) : (
                <Alert variant="outlined" severity="error">
                  {submissionError}
                </Alert>
              )}
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

export default NewPasswordChallengeForm;
