import { Typography } from "@mui/material";

import { useListOrganisations } from "../../store"
import DictionaryAutocomplete from "../dictionary-autocomplete";

interface SelectOrganisationProps {
    id: string;
    selectedOrganisationId: string[];
    organisationId: string;
    onChange: (value: string[]) => void;
    onClose?: () => void;
    disabled?: boolean;
}

const SelectOrganisation: React.FC<SelectOrganisationProps> = ({
    id,
    organisationId,
    selectedOrganisationId,
    onChange,
    onClose,
    disabled,
}: SelectOrganisationProps) => {

    const { data: organisations, isLoading } = useListOrganisations();

    const names: Record<string, string> = {};
    const selected: string[] = [];

    const options = []

    if (organisations) {
        for (let i = 0; i < organisations.length; i++) {

            const includes = selectedOrganisationId.includes(organisations[i].organisationId);

            if (includes) {
                selected.push(organisations[i].organisationId);
            }

            names[organisations[i].organisationId] = organisations[i].name;
            options.push(organisations[i].organisationId);
        }
    }

    return (
        <>
            {isLoading ?
                <Typography>Loading...</Typography>
                :
                <DictionaryAutocomplete
                    id={id}
                    selectName="Organisations"
                    selected={selected}
                    requiredIds={[organisationId]}
                    options={options}
                    names={names}
                    onChange={onChange}
                    onClose={onClose}
                    disabled={disabled}
                />
            }
        </>
    )
}

export default SelectOrganisation;