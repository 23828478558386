import React from "react";

import { Stack } from "@mui/material";

import { Meeting, MeetingJoinInfo } from "../../types";

import SelectOrAddAttendee from "./select-or-add-attendee";

interface EditAttendeesProps {
    meeting: Meeting,
    joinInfo?: MeetingJoinInfo,
    attendees: string[],
    waiting: boolean,
    organisationIds: string[],
    updateAttendees: (attendeesId: string[]) => void
}

const EditAttendees: React.FC<EditAttendeesProps> = ({
    meeting,
    joinInfo,
    organisationIds,
    attendees,
    waiting,
    updateAttendees,
}) => {

    const required = (joinInfo && joinInfo.confirmedAttendeesId) ?
        joinInfo.confirmedAttendeesId : meeting.confirmedAttendeesId || []

    return (
        <Stack
            spacing={2}
            padding={1}
            direction={"row"}
            minWidth={"200px"}
            alignItems={"center"}
            justifyItems={"center"}
            sx={{
                borderRadius: "0.5rem",
            }}>
            <SelectOrAddAttendee
                requiredIds={required}
                disabled={waiting}
                organisationIds={organisationIds}
                selected={attendees}
                onSelect={updateAttendees} />
        </Stack>
    )
}

export default EditAttendees;