import React from "react";
import {
    UseMutationResult,
    UseQueryResult,
    useMutation,
    useQuery,
    useQueryClient,
} from "@tanstack/react-query";
import { AuthContext } from "./context/auth-context";
import {
    Meeting, Organisation, Patient, User, UserType,
} from "./types";

import MeetingsAPIService from "./services/meetings-api-service";

import UsersServiceAPI from "./services/users-service";
import OrganisationAPIService from "./services/organisation-api-service";
import PatientService from "./services/patient-service";

//////////// Meetings ////////////
export const useMeetingList = (): UseQueryResult<Meeting[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["meetings"],
        queryFn: async (): Promise<Meeting[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }

            return MeetingsAPIService.listMeetings(authToken)
        },
        initialData: [],
    });
};

export const useGetMeeting = (meetingId: string): UseQueryResult<Meeting | null> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["meetings", meetingId],
        queryFn: async (): Promise<Meeting | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.getMeeting(authToken, meetingId)
        },
    });
}

export const useGetMeetingsWithIds = (meetingIds: string[]): UseQueryResult<(Meeting | null)[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["meetings", meetingIds],
        queryFn: async (): Promise<(Meeting | null)[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }
            return MeetingsAPIService.getMeetingsWithIds(authToken, meetingIds, true)
        },
    });
}

export const useAddMeeting = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.addMeeting(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["meetings"] });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
};

export const useDeleteMeeting = (): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (meeting: Meeting) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.deleteMeeting(authToken, meeting);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["meetings"] });
        },
    }) as UseMutationResult;
};

export const useUpdateMeeting = (): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.updateMeeting(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["meetings"] });
        },
    });
};

export const useUpdateAttendees = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return MeetingsAPIService.updateAttendees(authToken, values.meetingId, values.attendeesId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["meetings"] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
};

//////////// PhotoUrl ////////////
export const useGetPhotoUrls = (userId: string, meetingId: string): UseQueryResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["photoUrls"],
        queryFn: async (): Promise<string[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }
            return MeetingsAPIService.getPhotoUrls(authToken, userId, meetingId)
        },
        initialData: [],
    });
}

//////////// Users ////////////
export const useGetUser = (userId: string): UseQueryResult<User> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["users", userId],
        queryFn: async (): Promise<User | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return UsersServiceAPI.getUser(authToken, userId)
        }
    });
}

export const useListUsers = (): UseQueryResult<User[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    return useQuery({
        queryKey: ["users"],
        queryFn: async (): Promise<User[] | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.listUsers(authToken)
        },
    });
}

export const useGetUsersWithIds = (userIds: string[], showDeleted: boolean): UseQueryResult<(User | null)[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);
    return useQuery({
        queryKey: ["users", userIds],
        queryFn: async (): Promise<(User | null)[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }
            return UsersServiceAPI.getUsersWithIds(authToken, userIds, showDeleted)
        },
    });
}

export const useGetCurrentUser = (): UseQueryResult<User> => {
    const { userId } = React.useContext(AuthContext);
    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["users", userId],
        queryFn: async (): Promise<User | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.getUser(authToken, userId)
        },
    });
}

export const useUpdateUser = (onSuccess?: () => void, onFailure?: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return UsersServiceAPI.updateUser(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            if (onSuccess) {
                onSuccess();
            }
        },
        onError: (error: any) => {
            if (onFailure) {
                onFailure(error);
            }
        },
    });
}

export const useTransferUserOrganisation = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return UsersServiceAPI.transferUser(authToken, values.userId, values.organisationId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
}

export const useAddTemporaryUser = (onSuccess: (user: User) => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return UsersServiceAPI.addTemporaryUser(authToken, values);
        },
        onSuccess: (data: any, variables: any) => {
            queryClient.invalidateQueries({ queryKey: ["users"] });

            const user: User = {
                userId: variables?.userId || "",
                fullName: variables?.fullName || "",
                jobTitle: variables?.jobTitle || "",
                userType: variables?.userType as UserType || UserType.Temporary,
                organisationId: variables?.organisationId || "",
            }

            console.log("User added", user);

            onSuccess(user);
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
}

export const useAdminAddUser = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.adminAddUser(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
}

export const useAdminDeleteUser = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (userId: string) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.adminDeleteUser(authToken, userId);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    }) as UseMutationResult;
}

export const useAdminUpdateUser = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return UsersServiceAPI.adminUpdateUserType(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["users"] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
}

//////////// Organisation ////////////

export const useAddOrganisation = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return OrganisationAPIService.addOrganisation(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["organisation"] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        }
    });
}

export const useListOrganisations = (): UseQueryResult<Organisation[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["organisation"],
        queryFn: async (): Promise<Organisation[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }
            return OrganisationAPIService.listOrganisations(authToken)
        },
        initialData: [],
    });
}

export const useGetOrganisation = (organisationId: string): UseQueryResult<Organisation | null> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["organisation", organisationId],
        queryFn: async (): Promise<Organisation | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return OrganisationAPIService.getOrganisation(authToken, organisationId)
        },
    });
}

export const useUpdateOrganisation = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }

            return OrganisationAPIService.updateOrganisation(authToken, values);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["organisation"] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
}

export const useDeleteOrganisation = (onSuccess: () => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (organisation: Organisation) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return OrganisationAPIService.deleteOrganisation(authToken, organisation);
        },
        onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["organisation"] });
            onSuccess();
        },
        onError: (error: any) => {
            onFailure(error);
        }
    }) as UseMutationResult;
}

export const useGetOrganisationByIds = (organisationIds: string[]): UseQueryResult<(Organisation | null)[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["organisation", organisationIds],
        queryFn: async (): Promise<(Organisation | null)[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return []
            }

            return OrganisationAPIService.getOrganisationByIds(authToken, organisationIds)
        },
    });
}


//////////// Patient ////////////

export const useListPatients = (): UseQueryResult<Patient[]> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["patients"],
        queryFn: async (): Promise<Patient[]> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return [];
            }

            return PatientService.listPatients(authToken)
        },
        initialData: [],
    });
};

export const useGetPatient = (patientId: string): UseQueryResult<Patient> => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    return useQuery({
        queryKey: ["patients", patientId],
        queryFn: async (): Promise<Patient | null> => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return PatientService.getPatient(authToken, patientId)
        },
    });
}

export const useAddPatient = (onSuccess: (patient: Patient) => void, onFailure: (err: any) => void): UseMutationResult => {

    const { tryGetStoredSession } = React.useContext(AuthContext);

    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (values: any) => {

            const authToken = await tryGetStoredSession();

            if (authToken.length == 0) {
                console.error("No auth authToken provided");
                return null;
            }
            return PatientService.addPatient(authToken);
        },
        onSuccess: (response: any) => {
            queryClient.invalidateQueries({ queryKey: ["patients"] });
            onSuccess(response);
        },
        onError: (error: any) => {
            onFailure(error);
        },
    });
};