import { useEffect, useState } from 'react';

import { DefaultBrowserBehavior } from 'amazon-chime-sdk-js';
import { isOptionActive, useAudioOutputs, useLocalAudioOutput, useMeetingManager, useMeetingStatus } from 'amazon-chime-sdk-component-library-react';
import { Divider } from '@mui/material';

import VolumeUpIcon from '@mui/icons-material/VolumeUp';
import VolumeOffIcon from '@mui/icons-material/VolumeOff';

import ControlButton from './control-button';
import TelehealthPopover, { CustomPopOverItemProps } from '../telehealth-popover';

interface AudioOutProps {
    startWithAudio: boolean;
    showText?: boolean;
    disabled?: boolean;
    showDropDown?: boolean;
}

const LabelledAudioButton: React.FC<AudioOutProps> = ({
    startWithAudio,
    showText,
    disabled = false,
    showDropDown = true
}) => {

    const status = useMeetingStatus();

    const meetingManager = useMeetingManager();
    const { devices, selectedDevice } = useAudioOutputs();
    const { isAudioOn, toggleAudio } = useLocalAudioOutput();
    const [dropdownOptions, setDropdownOptions] = useState<CustomPopOverItemProps[]>([]);

    useEffect(() => {
        if (status != 1) {
            return;
        }

        if (isAudioOn !== startWithAudio) {
            toggleAudio();
        }
    }, [startWithAudio, status]);

    const onSelectedChanged = (device: CustomPopOverItemProps) => {
        setSelected(device.id);
        console.log("Selected audio output device: " + device.id);
    }

    const setSelected = async (deviceId: string): Promise<void> => {
        try {
            if (new DefaultBrowserBehavior().supportsSetSinkId()) {
                await meetingManager.startAudioOutputDevice(deviceId);
            } else {
                console.error(
                    'AudioOutputControl cannot select audio output device because browser does not support setSinkId operation.'
                );
            }
        } catch (error) {
            console.error('AudioOutputControl failed to select audio output device');
        }
    };

    useEffect(() => {
        const getDropdownOptions = async (): Promise<void> => {

            const deviceList: CustomPopOverItemProps[] = [];

            devices.map(async (device) => {
                const popoverItem: CustomPopOverItemProps = {
                    label: device.label,
                    id: device.deviceId,
                    active: await isOptionActive(device, device.deviceId)
                };

                deviceList.push(popoverItem);
            });

            setDropdownOptions(deviceList);
        };

        getDropdownOptions();
    }, [
        devices,
        selectedDevice,
        meetingManager,
        meetingManager.startAudioOutputDevice,
    ]);

    return (
        <ControlButton
            disabled={disabled}
            activeIcon={<VolumeUpIcon color="action" />}
            inactiveIcon={<VolumeOffIcon color="action" />}
            active={isAudioOn}
            onClick={toggleAudio}
            activeLabel={"Disable Speaker"}
            inactiveLabel={"Enable Speaker"}
            showText={showText}>
            <Divider orientation="vertical" />
            {showDropDown &&
                <TelehealthPopover
                    options={dropdownOptions}
                    openPopOverLabel="Select Audio"
                    selectedOption={selectedDevice?.toString() || ""}
                    setSelectedOption={onSelectedChanged}
                />
            }
        </ControlButton >
    );
};

export default LabelledAudioButton;