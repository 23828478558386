import { DeviceLabels, MeetingManager } from "amazon-chime-sdk-component-library-react";
import { MeetingSessionConfiguration } from "amazon-chime-sdk-js";
import { AttendeeResponse } from "amazon-chime-sdk-component-library-react/lib/providers/MeetingProvider/types";

import { JoinRequestParams, MeetingJoinType } from "../types"

const BASE_URL = import.meta.env.VITE_API_URL as string + "/chime";

const sendJoinRequest = async (
    joinRequestParams: JoinRequestParams
): Promise<any> => {

    let response = null;

    try {
        response = await fetch(`${BASE_URL}/join`, {
            method: "POST",
            body: JSON.stringify(joinRequestParams),
            headers: {
                "Content-Type": "application/json",
            },
        });
    }
    catch (error) {
        console.log(error);
        return null;
    }

    if (!response.ok) {

        // get the message from the response's body
        const responseText = await response.json();

        throw new Error(`Error (${response.status}): ${responseText.message}`);
    }

    return response.json();
};

export const joinMeeting = async (meetingManager: MeetingManager, meetingId: string, attendeeId: string, userName: string, jobTitle: string, type: MeetingJoinType | undefined) => {
    // Fetch the meeting and attendee data from your server application
    const response = await sendJoinRequest({
        meetingId,
        attendeeId,
        userName,
        jobTitle,
        type,
        audioCapability: "SendReceive",
        videoCapability: "SendReceive",
        contentCapability: "SendReceive"
    });

    if (!response) {
        console.log("Error joining meeting")
        return;
    }

    meetingManager.getAttendee = getAttendee;

    const meetingSessionConfiguration = new MeetingSessionConfiguration(
        response.JoinInfo.Meeting,
        response.JoinInfo.Attendee
    );
    const options = {
        deviceLabels: DeviceLabels.AudioAndVideo,
    };

    // Use the join API to create a meeting session using the above data
    await meetingManager.join(meetingSessionConfiguration, options);

    // Start the session to join the meeting
    await meetingManager.start();
};

const getAttendee = (chimeAttendeeId: string, externalUserId?: string | undefined): Promise<AttendeeResponse> => {

    if (!externalUserId) {
        return Promise.resolve({
            name: "???",
            actualUserId: chimeAttendeeId,
        });
    }

    const split = externalUserId.split("#");

    if (split.length < 2) {
        return Promise.resolve({
            name: externalUserId,
            actualUserId: externalUserId,
            userType: "",
        });
    }

    if (split.length < 3) {
        return Promise.resolve({
            name: split[1],
            actualUserId: split[0],
            userType: "",
        });
    }

    let userType = split[2] || "";

    if (userType.length > 0) {
        for (const type of Object.values(MeetingJoinType)) {
            if (type.includes(userType)) {
                userType = type;
                break;
            }
        }
    }

    return Promise.resolve({
        name: split[1],
        actualUserId: split[0],
        userType: userType,
    });
}

export const joinMeetingMobile = async (meetingManager: MeetingManager, meetingId: string, attendeeId: string, userName: string, jobTitle: string) => {
    // Fetch the meeting and attendee data from your server application
    const response = await sendJoinRequest({
        meetingId,
        attendeeId,
        userName,
        jobTitle,
        type: MeetingJoinType.Phone,
        audioCapability: "None",
        videoCapability: "SendReceive",
        contentCapability: "None"
    });

    const meetingSessionConfiguration = new MeetingSessionConfiguration(
        response.JoinInfo.Meeting,
        response.JoinInfo.Attendee,
    );
    const options = {
        deviceLabels: DeviceLabels.Video,
    };

    meetingManager.getAttendee = getAttendee;

    // Use the join API to create a meeting session using the above data
    await meetingManager.join(meetingSessionConfiguration, options);

    // Start the session to join the meeting
    await meetingManager.start();
};