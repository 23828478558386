import { Stack, Typography } from "@mui/material"
import { Meeting } from "../../types"
import AttendeeOrganisationGrid from "../calendar/attendee-organisation-grid"

interface CompactMeetingDisplayProps {
    mostRecentMeeting: Meeting | null
    dateTimeString?: (date: Date) => string
}


const CompactMeetingDisplay: React.FC<CompactMeetingDisplayProps> = ({
    mostRecentMeeting,
    dateTimeString,
}) => {

    const dateString = (dateTimeString && mostRecentMeeting) ? dateTimeString(mostRecentMeeting?.startTime) : "???";

    return (
        <Stack
            direction={"row"}
            spacing={0.5}
            alignItems={"center"}>
            {mostRecentMeeting ?
                <>
                    < Typography variant="body1" fontSize={16} minWidth={"160px"} >
                        {dateString}
                    </Typography >
                    <AttendeeOrganisationGrid
                        meeting={mostRecentMeeting}
                        direction="row"
                        compact />
                </>
                : <Typography variant="body1" fontSize={16}>
                    None
                </Typography>}
        </Stack >
    )
}

export default CompactMeetingDisplay