import { useContext } from "react"
import { AuthContext } from "../../context/auth-context";
import { Card, Stack, Typography } from "@mui/material";
import ChangePassword from "./change-password";
import toast from "react-hot-toast";



const ChangePasswordCard: React.FC = () => {

    const { updatePassword } = useContext(AuthContext);

    const updatePasswordHandler = (oldPassword: string, newPassword: string) => {
        updatePassword(oldPassword, newPassword, (err) => {
            if (err) {
                console.log(err);
                toast.error("Failed to update password", { duration: 5000 });
            }
            else {
                toast.success("Password updated successfully", { duration: 5000 });
            }
        })
    }

    return (
        <Card>
            <Stack
                direction="column"
                spacing={2}
                padding={2}>
                <Typography variant="h4">
                    Change Password
                </Typography>
                <ChangePassword
                    updatePassword={updatePasswordHandler}
                />
            </Stack>
        </Card>
    )
}

export default ChangePasswordCard;