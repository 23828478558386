import {
  Button,
  Typography,
  Grid,
} from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"

import { useFormik } from "formik"

import { Meeting } from "../../types"

interface UpdateMeetingFormProps {
  handleClose: () => void
  meeting: Meeting
  updateMeeting: (meeting: Meeting) => void
}

const UpdateMeetingForm: React.FC<UpdateMeetingFormProps> = ({
  handleClose,
  meeting,
  updateMeeting,
}: UpdateMeetingFormProps) => {

  const onClose = () => {
    formik.resetForm();
    handleClose()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startTime: meeting?.startTime || new Date(),
    },
    validate: values => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (values.startTime < new Date()) {
        errors.startTime = "Start time must be in the future";
      }

      return errors;
    },
    onSubmit: values => {

      let duration = meeting.endTime.getTime() - meeting.startTime.getTime();

      const startTime = values.startTime;

      const endTime = new Date(startTime.getTime() + duration);

      const meetingName = startTime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })

      const updatedMeeting = {
        ...meeting,
        meetingName,
        startTime,
        endTime,
      }

      updateMeeting(updatedMeeting);
      onClose();
    },
  });

  const changeWithDatePicker = (value: any) => {
    formik.setFieldValue("startTime", value);
  }

  const submitDisabled = !formik.isValid || !formik.dirty || formik.isSubmitting;

  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DateTimePicker
            label="Start Time"
            value={formik.values.startTime}
            onChange={changeWithDatePicker}
            disablePast
            sx={{
              id: "startTime",
              name: "startTime",
              width: "100%",
            }} />
          {formik.touched.startTime && formik.errors.startTime &&
            <Typography color="error">{formik.errors.startTime as string}</Typography>}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="info"
            onClick={handleClose}
            fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={submitDisabled}
            color="success"
            type="submit"
            variant="outlined"
            fullWidth>
            Update
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default UpdateMeetingForm
