import { Skeleton, Stack } from "@mui/material";
import React from "react";
import { Navigate } from "react-router-dom";
import TelehealthToolbar from "../components/telehealth-toolbar";
import { userHasPermission, UserType } from "../types";
import { useGetCurrentUser } from "../store";
import ClearCallLogo from "../components/clear-call-logo";

export type ProtectedRouteProps = {
  authenticationPath: string;
  isAuthenticated: boolean;
  isLoading: boolean;
  requiredUserLevel: UserType;
  outlet: JSX.Element;
};

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  authenticationPath,
  isLoading,
  isAuthenticated,
  requiredUserLevel,
  outlet,
}: ProtectedRouteProps) => {

  const { data: currentUser, isLoading: userLoading } = useGetCurrentUser();

  const showLoading = isLoading || userLoading;

  if (showLoading) {
    return (
      <Stack
        justifyContent="center"
        alignItems="center"
        spacing={1}
        sx={{
          minHeight: "100vh",
        }}
      >
        <TelehealthToolbar >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
            sx={{
              width: "100vw",
              padding: 2,
            }}>
            <ClearCallLogo size={40} />
          </Stack>
        </TelehealthToolbar>
        <Skeleton width={"95vw"} height={"95vh"}></Skeleton>;
      </Stack>)
  }
  else if (isAuthenticated && userHasPermission(requiredUserLevel, currentUser)) {
    return outlet;
  } else {
    return <Navigate to={{ pathname: authenticationPath }} />;
  }
};

export default ProtectedRoute;
