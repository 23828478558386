import { MouseEventHandler } from "react";

import { IconButton, Stack, SxProps, Theme, Tooltip } from "@mui/material";
import { Link } from "react-router-dom";

interface TelehealthIconButtonProps {
    icon: React.ReactNode;
    tooltip: string;
    inactiveTooltip?: string;
    color?: "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
    disabled?: boolean;
    onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
    href?: string;
    sx?: SxProps<Theme>;
}

const TelehealthIconButton: React.FC<TelehealthIconButtonProps> = ({
    icon,
    tooltip,
    inactiveTooltip,
    color,
    disabled,
    href,
    onClick,
    sx
}) => {

    if (disabled && inactiveTooltip) {
        tooltip = inactiveTooltip;
    }

    return (
        <Tooltip title={tooltip}>
            <Stack
                height={"fit-content"}
                width={"fit-content"}
                justifyItems={"center"}
                alignContent={"center"}
                spacing={0}>
                {href &&
                    <IconButton
                        color={color}
                        disabled={disabled}
                        onClick={onClick}
                        sx={sx}>
                        <Link to={href}>
                            <Stack spacing={0}>
                                {icon}
                            </Stack>
                        </Link>
                    </IconButton >}
                {!href &&
                    <IconButton
                        color={color}
                        disabled={disabled}
                        onClick={onClick}
                        sx={sx}>
                        {icon}
                    </IconButton >}
            </Stack>
        </Tooltip>
    )
}

export default TelehealthIconButton;