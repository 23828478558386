import {
  Button,
  Typography,
  Grid,
} from "@mui/material"
import { DateTimePicker } from "@mui/x-date-pickers"

import { useFormik } from "formik"

import { Meeting, User } from "../../types"

import { useGetCurrentUser, useListPatients } from "../../store"

import SelectOrganisation from "./select-organisation"
import PatientSelect from "../patients/patient-select"
import SelectOrAddAttendee from "./select-or-add-attendee"
import React from "react"


interface MeetingFormProps {
  useStartDateInForm: boolean
  handleClose: () => void
  meeting: Meeting
  addMeeting: (meeting: Meeting) => void
}

const MeetingForm: React.FC<MeetingFormProps> = ({
  useStartDateInForm,
  handleClose,
  meeting,
  addMeeting,
}: MeetingFormProps) => {

  const [waitingOnAdd, setWaitingOnAdd] = React.useState<boolean>(false);

  const { data: currentUser } = useGetCurrentUser();
  const { data: patientList } = useListPatients();

  const onClose = () => {
    formik.resetForm();
    handleClose()
  }

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      startTime: meeting?.startTime || new Date(),
      organisationIds: meeting?.organisationIds || [],
      patientId: meeting?.patientId || "",
      attendeesId: meeting?.attendeesId || [],
    },
    validate: values => {
      const errors: Partial<Record<keyof typeof values, string>> = {};
      if (values.startTime < new Date()) {
        errors.startTime = "Start time must be in the future";
      }

      if (values.organisationIds.length < 2) {
        errors.organisationIds = `You must select at least one more organisation to invite.`;
      }

      if (values.patientId === "") {
        errors.patientId = "You must select a patient to invite.";
      }

      return errors;
    },
    onSubmit: values => {

      let duration = meeting.endTime.getTime() - meeting.startTime.getTime();

      const startTime = values.startTime;

      const endTime = new Date(startTime.getTime() + duration);

      const meetingName = startTime.toLocaleTimeString([], { hour: "numeric", minute: "2-digit" })

      const updatedMeeting = {
        ...meeting,
        meetingName,
        startTime,
        endTime,
        organisationIds: values.organisationIds,
        patientId: values.patientId,
        attendeesId: values.attendeesId
      }

      addMeeting(updatedMeeting);
      onClose();
    },
  });

  const changeWithDatePicker = (value: any) => {
    formik.setFieldValue("startTime", value);
  }

  const changePatient = (selected: string[]) => {
    const selectedId = selected.length > 0 ? selected[0] : ""

    formik.setFieldValue("patientId", selectedId);
  }

  const submitDisabled = !formik.isValid || !formik.dirty || formik.isSubmitting;

  const uncontrolledPicker = useStartDateInForm && !formik.touched.startTime

  const organisationChangeEvent = (organisationIds: string[]) => {
    formik.setTouched({ ...formik.touched, organisationIds: true });
    formik.setFieldValue("organisationIds", organisationIds);
  }

  const attendeeChangeEvent = (attendeesId: string[]) => {
    formik.setTouched({ ...formik.touched, attendeesId: true });
    formik.setFieldValue("attendeesId", attendeesId);
  }

  const createPatientList = () => {
    if (!patientList) {
      return []
    }

    const patientIdList: string[] = []

    for (let patient of patientList) {
      patientIdList.push(patient.patientId)
    }

    return patientIdList
  }

  const patientIds = createPatientList()

  const reportAddTemporaryUserStart = () => {
    console.log("Adding user started");
    setWaitingOnAdd(true);
  }

  const reportSuccess = (user?: User) => {

    if (user) {
      const newAttendeesId = [...formik.values.attendeesId, user.userId]
      formik.setFieldValue("attendeesId", newAttendeesId)
    }

    console.log("User added successfully");
    setWaitingOnAdd(false);
  }

  const reportError = () => {
    console.log("Failed to add user");
    setWaitingOnAdd(false);
  }


  return (
    <form onSubmit={formik.handleSubmit}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {uncontrolledPicker &&
            <DateTimePicker
              label="Start Time"
              value={formik.values.startTime}
              onChange={changeWithDatePicker}
              disabled={waitingOnAdd}
              disablePast
              sx={{
                id: "startTime",
                name: "startTime",
                width: "100%",
              }} />}
          {!uncontrolledPicker &&
            <DateTimePicker
              label="Start Time"
              onChange={changeWithDatePicker}
              disablePast
              disabled={waitingOnAdd}
              sx={{
                id: "startTime",
                name: "startTime",
                width: "100%",
              }} />}
          {formik.touched.startTime && formik.errors.startTime &&
            <Typography color="error">{formik.errors.startTime as string}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <SelectOrganisation
            id={"organisationIds"}
            disabled={waitingOnAdd}
            organisationId={currentUser?.organisationId || ""}
            selectedOrganisationId={formik.values.organisationIds}
            onChange={organisationChangeEvent}
          />
          {formik.touched.organisationIds && formik.errors.organisationIds &&
            <Typography color="error">{formik.errors.organisationIds}</Typography>}
        </Grid>
        <Grid item xs={12}>
          <SelectOrAddAttendee
            requiredIds={[]}
            disabled={waitingOnAdd}
            organisationIds={currentUser?.organisationId ? [currentUser.organisationId] : []}
            selected={formik.values.attendeesId}
            onSelect={attendeeChangeEvent}
            onSubmit={reportAddTemporaryUserStart}
            onSuccess={reportSuccess}
            onFail={reportError}
          />
        </Grid>
        <Grid item xs={12}>
          <PatientSelect
            disabled={waitingOnAdd}
            selectedPatientId={formik.values.patientId}
            onChange={changePatient}
            patientIdList={patientIds} />
          {formik.touched.patientId && formik.errors.patientId &&
            <Typography color="error">{formik.errors.patientId}</Typography>}
        </Grid>
        <Grid item xs={6}>
          <Button
            variant="outlined"
            color="info"
            onClick={handleClose}
            fullWidth>
            Cancel
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            disabled={submitDisabled}
            color="success"
            type="submit"
            variant="outlined"
            fullWidth>
            Schedule
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default MeetingForm
