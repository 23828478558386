import { Dialog } from "@mui/material";

import toast from "react-hot-toast";

import { useAddTemporaryUser, useAdminAddUser } from "../../store";

import AddUserForm from "./add-user-form";
import CancelButton from "../cancel-button";
import { User } from "../../types";

interface AddUserProps {
    open: boolean;
    organisationId: string;
    user?: User;
    showEmail?: boolean;
    onSuccess?: (user?: User) => void;
    onFail?: () => void;
    onSubmit?: () => void;
    onClose: () => void;
}

const addUserModal: React.FC<AddUserProps> = ({
    open,
    organisationId,
    user,
    showEmail = true,
    onSuccess,
    onFail,
    onSubmit,
    onClose
}: AddUserProps) => {

    const onAddSuccess = (user?: User) => {
        if (showEmail) {
            toast.success("Invitation sent successfully");
        }
        else {
            toast.success("User added successfully");
        }

        if (onSuccess) {
            onSuccess(user);
        }
    }

    const onAddError = (err: any) => {
        console.log(err);
        if (showEmail) {
            toast.error("Failed to send invitation");
        }
        else {
            toast.error("Failed to add user");
        }

        if (onFail) {
            onFail();
        }
    }

    const { mutate: addUser } = useAdminAddUser(onAddSuccess, onAddError);

    const { mutate: addTemporaryUser } = useAddTemporaryUser(onAddSuccess, onAddError);

    const submitForm = (user: User) => {

        if (showEmail) {
            addUser(user);
        } else {
            addTemporaryUser(user);
        }

        if (onSubmit) {
            onSubmit();
        }

        onClose();
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth>
            <CancelButton handleClose={onClose} />
            <AddUserForm
                showEmail={showEmail}
                user={user}
                organisationId={organisationId}
                addUser={submitForm} />
        </Dialog >
    );
}

export default addUserModal;