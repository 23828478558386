import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { Typography } from '@mui/material';

interface StringAvatarProps {
    name: string;
    size?: number;
}

const getInitials = (name: string) => {
    const nameSplit = name.split(' ');

    if (nameSplit.length === 0) {
        return '';
    }

    if (nameSplit[0].length === 0) {
        return '';
    }

    if (nameSplit.length === 1) {
        return nameSplit[0][0];
    }

    if (nameSplit[1].length === 0) {
        return nameSplit[0][0];
    }

    const firstLetter = nameSplit[0][0];
    const secondLetter = nameSplit[1][0];

    return `${firstLetter}${secondLetter}`;
}

const LetterAvatar: React.FC<StringAvatarProps> = ({
    name,
    size = 30
}) => {

    const initials = getInitials(name);

    return (
        <Avatar sx={{
            backgroundColor: "#055480",
            color: "primary.contrastText",
            width: size,
            height: size
        }}>
            <Typography variant="body1">
                {initials}
            </Typography>
        </Avatar>
    );
}

export default LetterAvatar;