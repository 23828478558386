import CircleIcon from '@mui/icons-material/Circle';
import { Stack, Typography } from '@mui/material';

interface NotificationCircleProps {
    count: number;
}

const NotificationCircle: React.FC<NotificationCircleProps> = ({
    count
}) => {
    return (
        <Stack>
            {count > 0 &&
                <CircleIcon color="error" sx={
                    {
                        position: "absolute",
                        zIndex: 100,
                        right: 3,
                        top: 3,
                        transform: "translate(50%, -50%)",
                        fontSize: "1.5rem"
                    }

                } />}
            {count > 0 &&
                <Typography variant="body1" sx={{
                    zIndex: 100,
                    position: "absolute",
                    right: 3,
                    top: 3,
                    transform: "translate(50%, -50%)",
                    color: "white",
                }}>
                    {count}
                </Typography>}
        </Stack >
    );
}

export default NotificationCircle;