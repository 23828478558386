import React, { useContext, useEffect, useState } from 'react';

import { once } from "events";

import { toUtf8 } from "@aws-sdk/util-utf8-browser";

import { mqtt, iot, mqtt5 } from 'aws-iot-device-sdk-v2';
import { AuthContext } from '../context/auth-context';
import { telehealthStaticCredentialProvider } from './telehealth-static-credential-provider';
import { QueryClient } from '@tanstack/react-query';

const region = import.meta.env.VITE_AWS_REGION as string;
const websocketEndPoint = import.meta.env.VITE_WEBSOCKET_API_ENDPOINT as string

const topic = 'meeting-updates';

const ConnectIoT: React.FC<React.PropsWithChildren> = ({ children }) => {

  const [clientConnection, setClientConnection] = useState<mqtt5.Mqtt5Client | null>(null);

  const { assumeIdentity, isAuthenticated } = useContext(AuthContext)

  const queryClient = new QueryClient();

  useEffect(() => {

    const waitToConnect = async () => {

      if (!isAuthenticated) {
        return
      }

      const identityCredentials = await assumeIdentity();

      if (!identityCredentials) {
        console.log('No credentials');
        return;
      }

      const { accessKeyId, secretKey, sessionToken, identityId } = identityCredentials;

      if (!accessKeyId || !secretKey || !sessionToken) {
        console.error('No access token or id token');
        return;
      }

      const credentials = new telehealthStaticCredentialProvider(accessKeyId, secretKey, sessionToken, region);

      const config = iot.AwsIotMqtt5ClientConfigBuilder.newWebsocketMqttBuilderWithSigv4Auth(
        websocketEndPoint,
        {
          region: region,
          credentialsProvider: credentials,
        }).build();

      const client = new mqtt5.Mqtt5Client(config);

      client.on("messageReceived", (eventData: mqtt5.MessageReceivedEvent): void => {
        console.log("Message Received event: " + JSON.stringify(eventData.message));
        if (eventData.message.payload) {
          console.log("  with payload: " + toUtf8(eventData.message.payload as Buffer));
        }
      });

      client.on('connectionSuccess', () => {
        console.log('Connected to AWS IoT');
      });

      client.on("messageReceived", (eventData: mqtt5.MessageReceivedEvent): void => {
        if (eventData.message.payload) {

          const message = toUtf8(eventData.message.payload as Buffer);

          if (message.includes('created') && message.includes('Meeting')) {
            console.log('Create Meeting');
            queryClient.invalidateQueries({
              queryKey: ['meetings'],
              refetchType: 'all'
            });
          }
        }
      });

      const attemptingConnect = once(client, "attemptingConnect");
      const connectionSuccess = once(client, "connectionSuccess");

      client.start();

      const subPacket: mqtt5.SubscribePacket =
      {
        subscriptions: [
          {
            topicFilter: topic,
            qos: mqtt.QoS.AtMostOnce,
          }
        ]
      }

      client.subscribe(subPacket);


      setClientConnection(client);
    }

    waitToConnect();

    return () => {
      console.log('Disconnecting from AWS IoT');

      if (clientConnection) {
        clientConnection.close();
      }
    };
  }, [isAuthenticated]);

  return (
    <>
      {children}
    </>
  );
};

export default ConnectIoT