import React, { PropsWithChildren } from 'react';

import { Popover, Button, Stack, Typography, MenuList, MenuItem } from '@mui/material';
import KeyBoardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import LetterAvatar from '../letter-avatar';

interface OptionsDropdownProps {
    dropdownName: string
    displayedName: string
}

export const OptionsDropdown: React.FC<PropsWithChildren<OptionsDropdownProps>> = ({
    dropdownName,
    displayedName,
    children,
}) => {

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const open = Boolean(anchorEl);
    const id = open ? `${dropdownName}-options-dropdown` : undefined;

    const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopover = () => {
        setAnchorEl(null);
    };

    return (
        <>
            <Button
                onClick={openPopover}
                variant='contained'
                sx={{
                    minWidth: "165px",
                }} >
                <Stack
                    direction='row'
                    spacing={1}
                    alignContent={"center"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width="100%"
                >
                    <LetterAvatar name={displayedName} />
                    <Typography variant='body1'>
                        {displayedName}
                    </Typography>
                    <KeyBoardArrowDownIcon />
                </Stack>
            </Button >
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={closePopover}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}>
                <MenuList sx={{
                    minWidth: "165px",
                }}>
                    {children}
                </MenuList>
            </Popover>
        </>
    );
};

export default OptionsDropdown;