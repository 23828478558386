import { useContext } from "react";
import LocalViewTile from "./local-view-tile";
import { MeetingInfoContext } from "../../context/meeting-info-context";
import { useLocalVideo } from "amazon-chime-sdk-component-library-react";
import StyledIconShowSelfButton from "./styled-icon-show-self-button";

interface LocalViewTileProps {
    viewHeight: number;
    viewWidth: number;
}

const CornerLocalView: React.FC<LocalViewTileProps> = ({
    viewHeight,
    viewWidth,
}: LocalViewTileProps) => {

    const { showSelf } = useContext(MeetingInfoContext);
    const { isVideoEnabled } = useLocalVideo();

    return (
        <>
            {showSelf && <LocalViewTile
                showNamePlate={true}
                overrideCSS={{
                    position: "absolute",
                    display: "flex",
                    bottom: "1rem",
                    right: "1rem",
                    width: "20vw",
                    maxHeight: "30vh",
                    height: "auto",
                }}
                viewHeight={viewHeight}
                viewWidth={viewWidth}
            />}
            <div style={{
                position: "absolute",
                bottom: showSelf ? "18vh" : "1rem",
                right: "1rem",
                zIndex: 100,
                display: isVideoEnabled ? "flex" : "none",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
            }}>
                <StyledIconShowSelfButton right={true} zIndex={100} />
            </div>
        </>
    )
}

export default CornerLocalView;