import React, { useContext, useEffect } from "react";

import { Paper, Stack, Typography } from "@mui/material";
import CameraAltIcon from '@mui/icons-material/CameraAlt';

import { WindowSizeContext } from "../context/window-size-context";

import { MeetingInfoContext } from "../context/meeting-info-context";
import CustomLocalView from "../components/meeting/custom-local-view";

import { useLocalVideo } from "amazon-chime-sdk-component-library-react";
import VideoUploadButton from "../components/meeting/video-upload-button";

const MobileUpload: React.FC = () => {

    const { isPortrait } = useContext(WindowSizeContext);
    const { connected } = useContext(MeetingInfoContext)

    const uploadComplete = () => {
        window.location.href = "/callended?message=Photo uploaded.";
    }

    const { isVideoEnabled, toggleVideo } = useLocalVideo();

    const [hasEnabledVideo, setHasEnabledVideo] = React.useState(false);

    useEffect(() => {
        if (hasEnabledVideo) {
            return;
        }

        if (!connected) {
            return;
        }

        setHasEnabledVideo(true);

        if (!isVideoEnabled) {
            toggleVideo();
        }

    }, [connected]);

    return (
        <Stack direction={"column"}
            sx={{
                height: "100vh",
                width: "100vw",
                backgroundColor: "#2E3033",
                alignContent: "center",
                justifyContent: "center",
                justifyItems: "center",
                alignItems: "center",
            }}
        >
            <Stack direction={"column"}
                spacing={1}
                sx={{
                    alignItems: "center",
                    justifyContent: "center",
                    padding: 1,
                }}>
                <CustomLocalView
                    showNamePlate={false}
                    showNotSharingMessage={false}
                    viewHeight={100}
                    viewWidth={100} />
            </Stack>
            {connected &&
                <>
                    <Stack
                        direction={isPortrait ? "column" : "row"}
                        spacing={2} alignContent={"center"}
                        justifyContent={"center"}
                        sx={{
                            height: "fit-content",
                            width: "fit-content",
                            position: "fixed",
                            top: "10px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            zIndex: 100
                        }}>
                        <Paper
                            sx={{
                                height: "fit-content",
                                width: "fit-content",
                                borderRadius: 2,
                                padding: 1,
                                backgroundColor: "lightgrey",
                            }}
                            elevation={20}
                        >
                            <Stack
                                direction={"row"}
                                spacing={1}
                                alignContent={"center"}
                                justifyContent={"center"}
                                sx={{ width: "100%" }} >
                                <CameraAltIcon color="action" />
                                <Typography variant="h5" display={"inline-flex"}>Preview</Typography>
                            </Stack>
                        </Paper>
                    </Stack >
                    <Stack
                        direction={"column"}
                        spacing={2} alignContent={"center"}
                        justifyContent={"center"}
                        sx={{
                            mt: 4,
                            padding: 1,
                            borderRadius: "10px",
                            height: "fit-content",
                            width: "fit-content",
                            position: "fixed",
                            bottom: "20px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            zIndex: 100
                        }}>
                        <VideoUploadButton uploadComplete={uploadComplete} />
                    </Stack>
                </>
            }
        </Stack >
    );
};

export default MobileUpload;