import React, { FC, MouseEventHandler } from 'react';

import { Button, Stack, SxProps, Theme, Tooltip, Typography } from '@mui/material';

export interface TelehealthStyledButtonProps {
    children?: React.ReactNode;
    disabled?: boolean;
    label: string;
    variant?: "text" | "outlined" | "contained";
    sx?: SxProps<Theme>;
    tooltip?: string;
    icon?: React.ReactNode;
    showText?: boolean;
    onClick: MouseEventHandler<HTMLButtonElement> | undefined;
    buttonRef?: React.RefObject<HTMLButtonElement>;
    navigationPath?: string;
    spacing?: number;
}

export const TelehealthStyledButton: FC<React.PropsWithChildren<TelehealthStyledButtonProps>> = ({
    icon,
    children,
    onClick,
    label,
    variant = "outlined",
    sx,
    tooltip,
    showText,
    disabled = false,
    buttonRef,
    navigationPath,
    spacing = 1,
}) => {

    tooltip = tooltip || label;

    return (
        <Tooltip title={tooltip}>
            <Stack
                direction="row"
                spacing={spacing}
                width={"fit-content"}
                height={"fit-content"}>
                <Button
                    variant={variant}
                    disabled={disabled}
                    onClick={onClick}
                    aria-label={label}
                    ref={buttonRef}
                    href={navigationPath}
                    sx={{
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "0.5rem",
                        ...sx,
                    }}
                >
                    <Stack
                        direction="row"
                        spacing={spacing}
                        alignItems="center"
                        justifyItems={"center"}>
                        {icon}
                        {showText ?
                            <Typography variant="body1" >
                                {label}
                            </Typography>
                            : null}
                    </Stack>
                </Button>
                {children}
            </Stack >
        </Tooltip>
    );
};

export default TelehealthStyledButton;