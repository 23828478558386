import { Organisation } from "../../types";

export const createMeetingName = (meetingName: string, organisationList?: (Organisation | null)[], organisation?: Organisation | null) => {

    if (!organisation) {
        return {
            organisationNameList: meetingName,
            organisationId: ""
        }
    }

    if (!organisationList) {
        return {
            organisationNameList: meetingName,
            organisationId: ""
        }
    }

    if (organisationList.length === 0) {
        return {
            organisationNameList: meetingName,
            organisationId: organisation.organisationId
        }
    }

    let organisationId = "";

    const organisationNameList: string[] = [];
    for (const currentOrganisation of organisationList) {

        if (!currentOrganisation) {
            continue;
        }

        if (currentOrganisation.organisationId === organisation.organisationId) {
            continue;
        }

        if (currentOrganisation.organisationId && organisationId === "") {
            organisationId = currentOrganisation.organisationId;
        }

        if (organisationNameList.includes(currentOrganisation.name)) {
            continue;
        }

        organisationNameList.push(currentOrganisation.name);
    }

    if (organisationNameList.length === 0) {
        return {
            organisationNameList: meetingName,
            organisationId: organisation.organisationId
        }
    }

    const finalString = organisationNameList.length > 1 ? organisationNameList.join(", ") : organisationNameList[0];

    return {
        organisationNameList: finalString,
        organisationId: organisationId
    }
}