import TelehealthStyledButton, { TelehealthStyledButtonProps } from "../telehealth-styled-button";
import NotificationCircle from "./notification-circle";

interface UnseenItemButtonProps extends TelehealthStyledButtonProps {
    total: number;
    unseen: number;
    typeName: string;
    showIcon?: boolean;
}

const UnseenItemButton: React.FC<UnseenItemButtonProps> = ({
    total,
    unseen,
    showText,
    typeName,
    icon,
    onClick,
    ...props
}) => {

    const createDownloadDescription = () => {
        if (total === 0) {
            return `No ${typeName}s to download`;
        }

        return `Download ${typeName} 1 of ${total}`;
    }

    return (
        <TelehealthStyledButton
            {...props}
            onClick={onClick}
            disabled={total === 0}
            label={createDownloadDescription()}
            tooltip={`View and download ${typeName}s`}
            showText={showText}
            spacing={0}
            icon={
                <>
                    {icon}
                    {unseen > 0 &&
                        <NotificationCircle count={unseen} />}
                </>}
        />
    );
};

export default UnseenItemButton;