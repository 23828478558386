import { ListItemIcon, ListItemText, MenuItem } from "@mui/material";
import { PropsWithChildren } from "react";


interface TelehealthMenuItemProps {
    label: string;
    icon: React.ReactNode;
    disabled?: boolean;
    onClick?: () => void;
}

const TelehealthMenuItemProps: React.FC<PropsWithChildren<TelehealthMenuItemProps>> = ({
    label,
    children,
    icon,
    disabled,
    onClick,
}) => {
    return (
        <MenuItem onClick={onClick} disabled={disabled}>
            <ListItemIcon>
                {icon}
            </ListItemIcon>
            <ListItemText>
                {label}
            </ListItemText>
            {children}
        </MenuItem>
    );
}

export default TelehealthMenuItemProps;