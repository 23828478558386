import { useContext } from "react";
import { useNavigate } from "react-router-dom";

import LogoutIcon from '@mui/icons-material/Logout';

import { AuthContext } from "../../context/auth-context";
import TelehealthStyledButton from "../telehealth-styled-button";
import TelehealthMenuItem from "../telehealth-menu-item";

const LogoutButton = () => {

    const { logout, } = useContext(AuthContext);
    const navigate = useNavigate();

    const onClickLogout = () => {
        logout();
        navigate("/login");
    };


    return (
        <TelehealthMenuItem
            onClick={onClickLogout}
            icon={<LogoutIcon color="error" />}
            label="Log Out" />
    );
}

export default LogoutButton;