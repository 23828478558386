import React, { useEffect, useRef } from "react";

import { Paper, Popper, Stack } from "@mui/material";
import QrCode2Icon from '@mui/icons-material/QrCode2';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { MeetingInfoContext } from "../../context/meeting-info-context";
import { MeetingJoinType } from "../../types";

import TelehealthStyledButton from "../telehealth-styled-button";
import JoinMeetingQR from "./join-meeting-qr";

interface ShowUploadQRProps {
    showIcon?: boolean;
    showText?: boolean;
}

const ShowUploadQR: React.FC<ShowUploadQRProps> = ({
    showIcon = false,
    showText = true,
}) => {

    const { meetingId, userId, userName, jobTitle } = React.useContext(MeetingInfoContext);

    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const buttonRef = useRef(null);

    useEffect(() => {
        setAnchorEl(buttonRef.current);
    }, []);

    const open = Boolean(anchorEl);
    const id = open ? 'qr-popover' : undefined;

    const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (anchorEl) {
            setAnchorEl(null);
        }
        else {
            setAnchorEl(event.currentTarget);
        }
    };

    return (
        <Stack spacing={2} direction={"column"} >
            <TelehealthStyledButton
                onClick={openPopover}
                icon={showIcon &&
                    <>
                        <QrCode2Icon />
                        {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                    </>
                }
                buttonRef={buttonRef}
                showText={showText}
                label={"Show Upload QR"} />
            <Popper
                open={open}
                anchorEl={anchorEl}
                id={id}
                sx={{
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                }}>
                <Paper
                    sx={{
                        padding: 0.5,
                        backgroundColor: "white",
                    }}>
                    <JoinMeetingQR
                        meetingId={meetingId}
                        userId={userId}
                        userName={userName}
                        jobTitle={jobTitle}
                        meetingType={MeetingJoinType.Phone}
                        qrCodeSize={160} />
                </Paper>
            </Popper>
        </Stack >
    )
}

export default ShowUploadQR;