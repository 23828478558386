import React from "react"
import { SetStateAction, Dispatch } from "react"

import { Dialog, Button, Stack, Typography } from "@mui/material"

import { Meeting, Organisation, User, canMeetingBeEdited, canUserEditMeetings } from "../../types"
import { CreateButtonInfo } from "./get-meeting-join-info"

import { JoinedAttendeeContext } from "../../context/joined-attendees-context"

import CancelButton from "../cancel-button"
import ConfirmationModal from "../confirmation-modal"
import UpdateMeetingForm from "./update-meeting-form"
import MeetingInfo from "./meeting-info"

interface MeetingInfoProps {
  open: boolean
  handleClose: Dispatch<SetStateAction<void>>
  deleteMeeting: (meeting: Meeting) => void
  updateMeeting: (meeting: Meeting) => void
  meeting: Meeting
  currentUser?: User
  userOrganisation?: Organisation | null
}

const MeetingInfoModal: React.FC<MeetingInfoProps> = ({
  open,
  handleClose,
  deleteMeeting,
  updateMeeting,
  meeting,
  currentUser,
  userOrganisation,
}: MeetingInfoProps) => {

  const onClose = () => {
    setEditInProgress(false)
    handleClose()
  }

  const [editInProgress, setEditInProgress] = React.useState(false)
  const [confirmationOpen, setConfirmationOpen] = React.useState(false)

  const canUserEdit = canUserEditMeetings(currentUser, userOrganisation);
  const meetingCanBeEdited = canMeetingBeEdited(meeting, currentUser?.userId || "", userOrganisation?.organisationId || "");

  const showEdit = canUserEdit.canEdit && meetingCanBeEdited.canEdit;

  const handleEditClose = () => {
    setEditInProgress(false)
  }

  const openEdit = () => {
    setEditInProgress(true)
  }

  const updateAndClose = (meeting: Meeting) => {
    updateMeeting(meeting);
    setEditInProgress(false);
  }

  const handleDelete = () => {
    setConfirmationOpen(true)
  }

  const confirmDelete = () => {
    deleteMeeting(meeting)
    closeConfirmation()
  }

  const closeConfirmation = () => {
    setConfirmationOpen(false)
  }

  return (
    <Dialog open={open} onClose={onClose}>
      <Stack width={"30vw"} >
        <Stack
          padding={2}
          spacing={0}>
          <CancelButton handleClose={onClose} />
          <Stack
            direction={"column"}
            spacing={1}>
            {editInProgress &&
              <>
                <Typography variant={"h5"}>Reschedule Meeting</Typography>
                <UpdateMeetingForm
                  handleClose={handleEditClose}
                  meeting={meeting}
                  updateMeeting={updateAndClose} />
              </>}
            {!editInProgress &&
              <MeetingInfo
                checkJoinInfo={open}
                meeting={meeting}
                currentUser={currentUser}
                userOrganisation={userOrganisation}
              />}
            {showEdit && !editInProgress &&
              <Stack
                direction={"row"}
                spacing={1}
                justifyContent={"left"}>
                <Button
                  variant="outlined"
                  color="info"
                  onClick={openEdit}
                  fullWidth>
                  Reschedule Meeting
                </Button>
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleDelete}
                  fullWidth>
                  Cancel Meeting
                </Button>
              </Stack>}
            <ConfirmationModal
              open={confirmationOpen}
              accept={confirmDelete}
              onClose={closeConfirmation}
              messages={["Are you sure you want to cancel this meeting?"]}
            />
          </Stack>
        </Stack>
      </Stack>
    </Dialog >
  )
}

export default MeetingInfoModal
