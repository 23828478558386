import React, { useContext, useEffect } from "react";

import FileUploadIcon from '@mui/icons-material/FileUpload';

import { MeetingStatus, useLocalVideo, useMeetingManager } from "amazon-chime-sdk-component-library-react";

import toast from 'react-hot-toast';

import { uploadFileAndCreateDownloadURL } from "../../services/upload-service";
import { MeetingInfoContext } from "../../context/meeting-info-context";

import ControlButton from "../meeting/control-button";

interface UploadImageProps {
    capture: boolean;
    restartVideo: boolean;
    showText?: boolean;
    reportImageUploaded?: () => void;
    children?: React.ReactNode;
}

const UploadImageModal: React.FC<UploadImageProps> = ({
    capture,
    restartVideo,
    reportImageUploaded,
    showText = true,
    children
}: UploadImageProps) => {

    const imageUploader = React.useRef<any>(null);

    const [image, setImage] = React.useState<File | null>(null);

    const [uploading, setUploading] = React.useState(false);

    const { userId, meetingId } = useContext(MeetingInfoContext);

    const meetingManager = useMeetingManager();
    const { isVideoEnabled, toggleVideo } = useLocalVideo();

    const [wasVideoEnabled, setWasVideoEnabled] = React.useState(false);

    const canUpload = !uploading && meetingManager.meetingStatus === MeetingStatus.Succeeded;

    const handleImageUpload = (e: any) => {

        if (restartVideo && wasVideoEnabled) {
            waitToRestartVideo();
        }

        const [file] = e.target.files;
        if (!file) {
            console.log("No file uploaded");
            return;
        }

        // check if is an image
        if (!file.type.match("image.*")) {
            console.log("File is not an image");

            const extension = file.name.split('.').pop();

            if (extension !== "ply") {
                toast.error("File is not an image");
                return;
            }
            else {
                console.log("File is a ply file");
            }
        }

        setImage(file);
    };

    useEffect(() => {
        if (!image) {
            return;
        }

        uploadFile(image);
    }, [image]);

    const uploadFile = async (image: File | null) => {
        if (!image) {
            return;
        }

        if (!meetingManager.audioVideo) {
            return;
        }

        if (!canUpload) {
            return;
        }

        setUploading(true);

        toast('Starting Upload');

        let photoUrl = undefined;
        try {
            photoUrl = await uploadFileAndCreateDownloadURL(image, userId, meetingId);
        } catch (e: any) {
            console.error("Error uploading file", e);
            toast.error("Error uploading file");
            setUploading(false);
            return;
        }

        //send download url to other users 
        meetingManager.audioVideo?.realtimeSendDataMessage(
            "download-url",
            JSON.stringify(photoUrl)
        );

        toast.success('File uploaded successfully!');

        setUploading(false);

        if (reportImageUploaded) {
            reportImageUploaded();
        }
    }

    const onUploaderClick = () => {
        //set selected input item null
        imageUploader.current.value = null;

        if (isVideoEnabled) {
            toggleVideo();
        }

        setWasVideoEnabled(isVideoEnabled);

        imageUploader.current.click();
    }

    useEffect(() => {
        if (!imageUploader.current) {
            return;
        }

        imageUploader.current.addEventListener("cancel", onCancel);
    }, [imageUploader]);

    const onCancel = () => {
        console.log("~~~ Image uploader cancelled ~~~");
        waitToRestartVideo();
    }

    const waitToRestartVideo = () => {

        console.log("~~~ waitToRestartVideo " + wasVideoEnabled);
        if (!wasVideoEnabled || isVideoEnabled) {
            return;
        }

        setWasVideoEnabled(false);

        setTimeout(() => {
            toggleVideo();
        }, 1000);
    }

    return (
        <ControlButton
            active={canUpload}
            disabled={!canUpload}
            activeIcon={<FileUploadIcon color="action" />}
            inactiveIcon={<FileUploadIcon color="action" />}
            activeLabel={"Upload Photo"}
            inactiveLabel={uploading ? "Upload in progress" : "Cannot upload photo"}
            showText={showText}
            onClick={onUploaderClick}>
            <input
                title="Upload Photo"
                type="file"
                accept="image/*"
                capture={capture}
                onChange={handleImageUpload}
                ref={imageUploader}
                style={{
                    display: "none"
                }} />
            {children}
        </ControlButton>
    );
}

export default UploadImageModal;