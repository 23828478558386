import React from "react";

import { Skeleton, Stack } from "@mui/material";

import { useAddPatient, useListPatients } from "../../store";

import PatientTable from "./patient-table";
import toast from "react-hot-toast";
import PatientCreatedDialogue from "./patient-created-dialogue";
import { Patient } from "../../types";

const PatientDisplay: React.FC = () => {

    const [currentPatient, setCurrentPatient] = React.useState<Patient | null>(null);

    const { data: patientList, isLoading } = useListPatients();

    const onSuccessfulAdd = (patient: Patient) => {
        console.log("Patient added successfully", patient);
        setCurrentPatient(patient);
        setCanAddPatient(true);
    }

    const closeSuccessDialogue = () => {
        setCurrentPatient(null);
    }

    const onFailedAdd = (err: any) => {
        console.error("Failed to add patient", err);
        setCanAddPatient(true);
        toast.error("Failed to add patient");
    }

    const { mutate: addPatient } = useAddPatient(onSuccessfulAdd, onFailedAdd);

    const [canAddPatient, setCanAddPatient] = React.useState<boolean>(true);

    const openAddPatient = () => {
        addPatient(patientList?.length || 0);
        setCanAddPatient(false);
    }

    return (
        <Stack spacing={2}>
            {isLoading &&
                <Skeleton width={"100%"} height={"100%"} />}
            {!isLoading &&
                <PatientTable
                    patients={patientList || []}
                    showEditButton={true}
                    showDeleteButton={true}
                    addButtonDisabled={!canAddPatient}
                    addPatientClick={openAddPatient} />}
            {currentPatient &&
                <PatientCreatedDialogue
                    open={currentPatient != null}
                    onClose={closeSuccessDialogue}
                    patient={currentPatient ? currentPatient : { patientId: "", created: new Date(), meetingIds: [] }}
                />}
        </Stack >
    )
}

export default PatientDisplay;