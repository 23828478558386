import React, { useEffect, useRef } from 'react';

import { useAudioVideo, useLocalVideo } from 'amazon-chime-sdk-component-library-react';

import { createCss } from '../../styles/video-styles';

import VideoLabel from './video-label';

interface LocalViewTileProps {
    showNamePlate: boolean;
    viewHeight: number;
    viewWidth: number;
    overrideCSS?: React.CSSProperties;
}

const LocalViewTile: React.FC<LocalViewTileProps> = ({
    showNamePlate,
    viewHeight,
    viewWidth,
    overrideCSS,
}) => {

    const { tileId, isVideoEnabled } = useLocalVideo();
    const audioVideo = useAudioVideo();
    const videoEl = useRef<HTMLVideoElement>(null);

    const [css, setCss] = React.useState(createCss(9, 16, viewHeight, viewWidth, 1));

    useEffect(() => {
        if (!audioVideo || !tileId || !videoEl.current || !isVideoEnabled) {
            return;
        }

        audioVideo.bindVideoElement(tileId, videoEl.current);

        setCss(
            createCss(
                videoEl.current.height,
                videoEl.current.width,
                viewHeight,
                viewWidth,
                1));

        return () => {
            const tile = audioVideo.getVideoTile(tileId);
            if (tile) {
                audioVideo.unbindVideoElement(tileId);
            }
        };
    }, [audioVideo, tileId, isVideoEnabled]);

    return (
        <>
            <video
                ref={videoEl}
                style={
                    overrideCSS ?
                        overrideCSS :
                        css
                }
            />
            {showNamePlate && isVideoEnabled && <VideoLabel
                name="You"
                distanceFromSide='1.25rem'
                zIndex={100}
                right={true} />}
        </>
    );
};

export default LocalViewTile;