import ClearCallLogoTitle from "../assets/ClearCallLogoTitle.png"

interface ClearCallLogoProps {
    size?: number
}

const ClearCallLogo: React.FC<ClearCallLogoProps> = ({
    size
}) => {

    const height = size ? size : 40
    const width = height * 5

    return (
        <img
            src={ClearCallLogoTitle}
            alt="Clear Call Health"
            height={`${height}px`}
            width={`${width}px`} />
    );
}

export default ClearCallLogo;