import React, { PropsWithChildren, useEffect } from "react"

import { canMeetingBeEdited, canUserEditMeetings, getEventStatus, Meeting, MeetingJoinInfo, Organisation, OrganisationType, User } from "../../types"
import { canJoin, MeetingJoinType } from "../../types"

import MeetingsAPIService from '../../services/meetings-api-service'

import { AuthContext } from "../../context/auth-context"
import { JoinedAttendeeContext } from "../../context/joined-attendees-context"

interface MeetingInfoJoinedProps {
  open: boolean
  meeting: Meeting
}

export const CreateButtonInfo = (meeting?: Meeting, joinedInfo?: MeetingJoinInfo, user?: User, organisation?: Organisation | null) => {

  const buttonType = organisation?.type === OrganisationType.Metro ? MeetingJoinType.Metro : MeetingJoinType.Remote;

  if (!meeting) {
    return {
      canJoinMeeting: false,
      canEdit: false,
      buttonType,
      canJoinMessage: "Can't join meeting",
    }
  }

  const canJoinMeeting = canJoin(meeting)

  const canJoinMessage = getEventStatus(meeting, joinedInfo?.joinedIds || [], joinedInfo?.startTime);

  const canUserEdit = canUserEditMeetings(user, organisation);
  const meetingCanBeEdited = canMeetingBeEdited(meeting, user?.userId || "", organisation?.organisationId || "");

  const canEdit = canUserEdit.canEdit && meetingCanBeEdited.canEdit;

  return {
    canJoinMeeting,
    canEdit,
    buttonType,
    canJoinMessage,
  }
}

const GetMeetingJoinInfo: React.FC<PropsWithChildren<MeetingInfoJoinedProps>> = ({
  open,
  meeting,
  children,
}: PropsWithChildren<MeetingInfoJoinedProps>) => {

  const { getJoinedAttendees, setJoinedAttendees } = React.useContext(JoinedAttendeeContext)

  const joinInfo = getJoinedAttendees(meeting?.meetingId || "")

  const [currentInterval, setCurrentInterval] = React.useState<NodeJS.Timeout | null>(null)

  const { tryGetStoredSession } = React.useContext(AuthContext)

  const canJoinMeeting = canJoin(meeting)

  useEffect(() => {
    const fetchJoinedAttendees = async () => {

      const authToken = await tryGetStoredSession()

      try {
        const joinedList = await MeetingsAPIService.getJoinedAttendees(authToken, meeting.meetingId)
        setJoinedAttendees(meeting.meetingId, joinedList)

        console.log("fetched joined attendees", joinedList)

      } catch (error) {
        console.log(error)
      }
    }

    const isInterval = currentInterval ? true : false

    if (currentInterval) {
      console.log("clearing interval")
      clearInterval(currentInterval)
    }

    if (!open || !canJoinMeeting) {
      console.log("clearing interval")
      if (isInterval) {
        setCurrentInterval(null)
      }

      if (!canJoinMeeting
        && joinInfo
        && joinInfo.joinedIds
        && joinInfo.joinedIds.length > 0) {
        setJoinedAttendees(meeting.meetingId, {
          ...joinInfo,
          joinedIds: [],
        })
      }
      return;
    }

    fetchJoinedAttendees()

    // call api every 60 seconds
    const interval = setInterval(() => {
      fetchJoinedAttendees()
    }, 30000)

    setCurrentInterval(interval)

    // clean up interval 
    return () => {
      clearInterval(interval)
      setCurrentInterval(null)
    }

  }, [meeting, open])

  return (
    <>
      {children}
    </>
  )
}

export default GetMeetingJoinInfo