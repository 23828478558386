import React, { FC } from 'react';

import { Popover, Button, Stack } from '@mui/material';

import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

export interface TelehealthPopoverProps {
    openPopOverLabel: string;
    options: CustomPopOverItemProps[];
    selectedOption: string | undefined;
    setSelectedOption: (option: CustomPopOverItemProps) => void;
}

export interface CustomPopOverItemProps {
    label: string;
    id: string;
    active: boolean;
}

export const TelehealthPopover: FC<
    React.PropsWithChildren<TelehealthPopoverProps>
> = ({
    options,
    selectedOption,
    setSelectedOption,
    openPopOverLabel,
}) => {

        const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

        const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const closePopover = () => {
            setAnchorEl(null);
        };

        const open = Boolean(anchorEl);
        const id = open ? 'simple-popover' : undefined;

        const selectOption = (option: CustomPopOverItemProps) => {
            setSelectedOption(option);
            closePopover();
        }

        const createOptions = () => {
            return options.map((option: CustomPopOverItemProps) => {
                return (
                    <Button
                        disabled={itemDisabled(option)}
                        onClick={() => selectOption(option)}
                        sx={{
                            justifyContent: "flex-start",
                        }}
                        id={option.id}
                        key={option.id}>
                        {option.label}
                    </Button >
                );
            });
        }

        const itemDisabled = (option: CustomPopOverItemProps) => {
            if (!option.active) {
                return true;
            }

            return selectedOption == option.id;
        }

        return (
            <>
                <IconButton onClick={openPopover} aria-label={openPopOverLabel}>
                    <KeyboardArrowUpIcon />
                </IconButton>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={closePopover}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}>
                    <Stack direction="column">
                        {createOptions()}
                    </Stack>
                </Popover>
            </>
        );
    };

export default TelehealthPopover;