import { Card, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';

import { Patient } from "../../types";

import PatientTableRow from "./patient-table-row";
import TelehealthStyledButton from "../telehealth-styled-button";

interface PatientTableProps {
    patients: Patient[];
    addButtonDisabled: boolean;
    addPatientClick: () => void;
    showEditButton?: boolean;
    showDeleteButton?: boolean;
    updatePatient?: (patient: Patient) => void;
    deletePatient?: (patient: Patient) => void;
}

const PatientTable: React.FC<PatientTableProps> = ({
    patients,
    addButtonDisabled,
    addPatientClick,
    showEditButton,
    showDeleteButton,
    updatePatient,
    deletePatient,
}) => {

    patients.sort((a, b) => {

        if (a.patientId === b.patientId) {
            return 0;
        }

        if (a.patientId === null) {
            return -1;
        }

        if (b.patientId === null) {
            return 1;
        }

        if (a.patientId === null && b.patientId === null) {
            return 0;
        }

        if (a.patientId < b.patientId) {
            return -1;
        }

        return 1;
    });

    return (
        <Card sx={{
            padding: 1,
        }}>
            <TableContainer component={Stack}>
                <Stack
                    direction="row"
                    padding={1}
                    alignItems={"center"}
                    justifyContent={"space-between"}>
                    <Typography variant="h4">
                        Patients
                    </Typography>
                    <TelehealthStyledButton
                        onClick={addPatientClick}
                        variant="contained"
                        label="Add Patient"
                        showText={true}
                        disabled={addButtonDisabled}
                        icon={
                            <AddIcon />
                        }
                    />
                </Stack>
                <Table
                    sx={{ width: "100%" }}
                    aria-label="patient table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Patient Id
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Created
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Last Meeting
                                </Typography>
                            </TableCell>
                            <TableCell align="left">
                                <Typography variant="body1" fontSize={16}>
                                    Next Meeting
                                </Typography>
                            </TableCell>
                            <TableCell align="right">
                                <Typography variant="body1" fontSize={16}>
                                    Actions
                                </Typography>
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {patients.map((patient) => {
                            return (
                                <TableRow key={patient.patientId}>
                                    <PatientTableRow
                                        patient={patient}
                                        canEdit={showEditButton}
                                        canDelete={showDeleteButton}
                                        deletePatient={deletePatient}
                                        updatePatient={updatePatient}
                                    />
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Card>
    );
}

export default PatientTable;