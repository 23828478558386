import { Stack } from "@mui/material"
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from '@mui/icons-material/Save';

import TelehealthIconButton from "../telehealth-icon-button"

interface EditOrganisationButtonsProps {
    waiting: boolean
    editInProgress: boolean
    canSubmit: boolean
    onSubmit: () => void
    onEditClick: () => void
}

const EditOrganisationButtons: React.FC<EditOrganisationButtonsProps> = ({
    waiting,
    editInProgress,
    canSubmit,
    onSubmit,
    onEditClick,
}) => {
    return (
        <Stack direction="row">
            {editInProgress && <TelehealthIconButton
                icon={<SaveIcon />}
                onClick={onSubmit}
                disabled={waiting || !canSubmit}
                tooltip="Save Changes" />}
            <TelehealthIconButton
                icon={
                    editInProgress ?
                        <CancelIcon sx={{
                            width: 25,
                            height: 25,
                        }} /> :
                        <EditIcon sx={{
                            width: 25,
                            height: 25,
                        }} />}
                onClick={onEditClick}
                tooltip={
                    editInProgress ?
                        "Cancel edit" :
                        "Edit attendees from your organisation"} />
        </Stack>
    )
}

export default EditOrganisationButtons;