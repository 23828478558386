import { QRCodeSVG } from "qrcode.react";
import { MeetingJoinType } from "../../types";
import { createURL } from "./create-meeting-url";

interface JoinMeetingQRProps {
    meetingId: string;
    userName: string;
    jobTitle: string;
    userId: string;
    meetingType: MeetingJoinType;
    qrCodeSize?: number;
}

const JoinMeetingQR: React.FC<JoinMeetingQRProps> = ({
    meetingId,
    userId,
    userName,
    jobTitle,
    meetingType,
    qrCodeSize = 200,
}: JoinMeetingQRProps) => {

    const url = createURL(meetingType, meetingId, userName, jobTitle, userId);

    return (
        <QRCodeSVG value={url} size={qrCodeSize} />
    );
}

export default JoinMeetingQR;