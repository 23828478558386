import { Patient } from "../types";

const uploadURL = import.meta.env.VITE_API_URL as string + "/patient";

const listPatients = async (
    authToken: string
): Promise<Patient[]> => {
    try {
        const response = await fetch(uploadURL, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const patients: Patient[] = data.map(
            (patient: any) => {
                const currentPatient: Patient = {
                    patientId: patient.patientId,
                    created: new Date(patient.created),
                    meetingIds: patient.meetingIds,
                }

                return currentPatient;
            });

        return patients;
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const getPatient = async (
    authToken: string,
    patientId: string
): Promise<Patient | null> => {

    if (patientId.length == 0) {
        return null;
    }

    let response = null;
    try {
        response = await fetch(`${uploadURL}/${patientId}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const patient: Patient = {
            patientId: data.patientId,
            created: new Date(data.created),
            meetingIds: data.meetingIds,
        };

        return patient;
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const addPatient = async (
    authToken: string,
): Promise<Patient> => {
    try {
        const response = await fetch(uploadURL, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${authToken}`,
                "Content-Type": "application/json",
            },
        });

        if (!response.ok) {
            throw new Error("Network response was not ok");
        }

        const data = await response.json();

        const patient: Patient = {
            patientId: data.patientId,
            created: new Date(data.created),
            meetingIds: data.meetingIds,
        };

        return patient;
    } catch (error) {
        console.log(error);
        throw error as Error;
    }
}

const PatientService = {
    listPatients,
    getPatient,
    addPatient,
};

export default PatientService;