import React from "react"

import { canJoin, Meeting, MeetingJoinInfo, Organisation, User } from "../../types"
import { useGetCurrentUser, useGetOrganisationByIds, useGetUsersWithIds } from "../../store"

import EditOrganisationTag from "./edit-organisation-tag"
import CompactOrganisationTag from "./compact-organisation-tag"
import Grid2 from "@mui/material/Unstable_Grid2/Grid2"

interface AttendeeOrganisationGridProps {
    meeting: Meeting,
    compact?: boolean,
    direction: "row" | "column",
    joinInfo?: MeetingJoinInfo,
}

const AttendeeOrganisationGrid: React.FC<AttendeeOrganisationGridProps> = ({
    meeting,
    compact = false,
    direction,
    joinInfo,
}) => {

    const attendees = joinInfo?.attendeesId || meeting.attendeesId || []
    const joinedAttendees = joinInfo?.joinedIds || []

    const { data: currentUser } = useGetCurrentUser()

    for (let i = 0; i < meeting.attendeesId.length; i++) {
        if (!attendees.includes(meeting.attendeesId[i])) {
            attendees.push(meeting.attendeesId[i])
        }
    }

    const attendeeList = attendees

    for (let i = 0; i < joinedAttendees.length; i++) {
        if (!attendeeList.includes(joinedAttendees[i])) {
            attendeeList.push(joinedAttendees[i])
        }
    }

    const { data: userList } = useGetUsersWithIds(attendeeList, true)

    const organisationIds = meeting.organisationIds
    if (userList) {
        for (const user of userList) {
            if (user && !organisationIds.includes(user.organisationId)) {
                organisationIds.push(user.organisationId)
            }
        }
    }

    const { data: organisations } = useGetOrganisationByIds(organisationIds)

    const userByOrganisationDict: { [key: string]: User[] } = {}

    const canJoinMeeting = canJoin(meeting)

    if (userList) {

        for (let i = 0; i < userList.length; i++) {
            const user = userList[i]

            if (!user) {
                continue;
            }

            if (!userByOrganisationDict[user.organisationId]) {
                userByOrganisationDict[user.organisationId] = []
            }
            userByOrganisationDict[user.organisationId].push(user)
        }
    }

    const createOrganisationUserDisplay = (organisation: Organisation | null, organisationId: string) => {

        if (!organisation) {
            return null
        }

        const users: User[] = userByOrganisationDict[organisationId] || []

        if (users.length === 0 && !organisation) {
            return null
        }

        const canEdit: boolean = Boolean(currentUser && organisation && organisation.organisationId === currentUser.organisationId)

        return (
            <Grid2
                key={organisationId + "-attendee-organisation-grid"}
                alignItems={"top"}
                justifyItems={"left"}>
                {compact &&
                    <CompactOrganisationTag
                        organisation={organisation || undefined}
                        users={users}
                    />}
                {!compact &&
                    <EditOrganisationTag
                        organisation={organisation || undefined}
                        joinInfo={joinInfo}
                        meeting={meeting}
                        invitedUsers={users}
                        canEdit={canEdit}
                        canJoin={canJoinMeeting}
                        joinedUsers={joinedAttendees}
                    />}
            </Grid2 >
        )
    }

    return (
        <Grid2
            container
            spacing={1}
            direction={direction}>
            {organisations &&
                organisations.map((organisation: Organisation | null) => (
                    createOrganisationUserDisplay(organisation, organisation?.organisationId || "")
                ))
            }
        </Grid2>
    )
}

export default AttendeeOrganisationGrid