import React from "react";

import { Card, Stack, Typography } from "@mui/material";

import { User } from "../../types";

import SetDetails from "./set-details";
import { useGetOrganisation } from "../../store";
import toast from "react-hot-toast";

interface UserDetailsProps {
    user: User;
}


const UserDetails: React.FC<UserDetailsProps> = ({
    user,
}) => {

    const { data: organisation } = useGetOrganisation(user?.organisationId || "");

    const organisationName = organisation?.name || "None";
    const organisationType = organisation?.type ? `(${organisation.type})` : "None";

    const formattedDateTimeString = (date?: Date) => {
        if (!date) {
            return "unknown";
        }

        console.log(date);

        return date.toLocaleDateString("en-GB", { weekday: "long", month: "long", day: "numeric", hour: "numeric", minute: "numeric" })
    }

    const lastLogin = formattedDateTimeString(user.lastLogin);

    const onSuccess = () => {
        toast.success("Details updated successfully", { duration: 5000 });
    }

    const onError = (error: any) => {
        console.log(error);
        toast.error("Failed to update details", { duration: 5000 });
    }

    return (
        <Stack direction="column" spacing={2}>
            <Card>
                <SetDetails onError={onError} onSuccess={onSuccess} />
            </Card>
            <Card>
                <Stack
                    direction="column"
                    spacing={1}
                    padding={2}>
                    <Typography variant="body1">
                        <b>Email:</b> {user?.email || "No email"}
                    </Typography>
                    <Typography variant="body1">
                        <b>Role:</b> {user.userType}
                    </Typography>
                    <Typography variant="body1">
                        <b>Organisation: </b>{organisationName} <i>{organisationType}</i>
                    </Typography>
                    <Typography variant="body1">
                        <b>Last Login: </b>{lastLogin}
                    </Typography>
                </Stack>
            </Card>
            <Card>
            </Card>
        </Stack >
    );
};

export default UserDetails;