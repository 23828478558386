import React, { PropsWithChildren } from "react";

import { Stack, Tooltip, Typography } from "@mui/material"

import { Organisation } from "../../types"


interface OrganisationTagProps {
    organisation?: Organisation
    attendees: number
    buttons?: JSX.Element
    canJoin: boolean
}

export const getOrganisationTagTooltip = (name: string, attendees: number) => {
    if (attendees === 0) {
        return `${name} (No attendees)`
    }

    if (attendees === 1) {
        return `${name} (1 attendee)`
    }

    return `${name} (${attendees} attendees)`
}

const OrganisationTag: React.FC<PropsWithChildren<OrganisationTagProps>> = ({
    organisation,
    attendees,
    buttons,
    children,
}) => {

    const name = organisation?.name || "No organisation"

    const title = attendees != undefined ? `${name} (${attendees})` : name

    const tooltip = getOrganisationTagTooltip(name, attendees)

    return (
        <Stack
            id={organisation?.organisationId + "organisation tag"}
            borderRadius={"0.5rem"}
            alignItems={"left"}
            justifyContent={"top"}
            spacing={1}
            minWidth={'100px'}
            width={"100%"}
            sx={{
                backgroundColor: "#e6e6e6",
            }}>
            <Stack
                direction={"row"}
                alignItems={"center"}
                justifyItems={"center"}
                justifyContent={"space-between"}
                width={"100%"}
                spacing={1}
                sx={{
                    borderTopRightRadius: "0.5rem",
                    borderTopLeftRadius: "0.5rem",
                    backgroundColor: organisation?.color || "lightgray",
                }}>
                <Tooltip title={tooltip}>
                    <Stack
                        height={"100%"}
                        alignItems={"center"}
                        justifyContent={"center"}
                        padding={1}>
                        <Typography variant="body1">
                            <b>{title}</b>
                        </Typography>
                    </Stack>
                </Tooltip>
                {buttons}
            </Stack>
            <Stack paddingX={"0.6rem"} paddingBottom={"0.4rem"}>
                {children}
            </Stack>
        </Stack>
    )
}

export default OrganisationTag