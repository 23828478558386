import { Button, Dialog, DialogActions, DialogTitle, Typography } from "@mui/material";

interface ConfirmationModalProps {
    open: boolean;
    accept: () => void;
    onClose: () => void;
    messages: string[];
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({
    open,
    accept,
    onClose,
    messages
}) => {

    return (
        <>
            <Dialog open={open} onClose={onClose} maxWidth={"lg"}>
                <DialogTitle component={"div"}>
                    {messages.map((message, index) => {
                        return (
                            <Typography key={index} variant="h4">
                                {message}
                            </Typography>
                        )
                    })}
                </DialogTitle>
                <DialogActions>
                    <Button onClick={accept} variant="outlined" color="info">Yes</Button>
                    <Button onClick={onClose} variant="outlined" color="error">No</Button>
                </DialogActions>
            </Dialog >
        </>
    )
}

export default ConfirmationModal;