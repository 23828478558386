import { createTheme, Theme, ThemeOptions } from "@mui/material/styles";

export const themeOptions: ThemeOptions = {
  palette: {
    mode: "light",
    primary: {
      main: "#1184b5",
      light: "#90caf9",
    },
    secondary: {
      main: "#115bb5",
    },
    text: {
      primary: "#444444",
      secondary: "#555555",
    }
  },
  typography: {
    fontFamily: "Lato, Nunito, Roboto, Helvetica, Arial, sans-serif",
    fontSize: 16,
    fontWeightBold: 600,
    h1: {
      fontWeight: 600,
      fontSize: "4rem",
      lineHeight: 1.21,
    },
    h2: {
      fontWeight: 600,
      fontSize: "1.875rem",
      lineHeight: 1.27,
    },
    h3: {
      fontWeight: 600,
      fontSize: "1.5rem",
      lineHeight: 1.33,
    },
    h4: {
      fontWeight: 600,
      fontSize: "1.25rem",
      lineHeight: 1.4,
    },
    h5: {
      fontWeight: 600,
      fontSize: "1rem",
      lineHeight: 1.5,
    },
    h6: {
      fontWeight: 400,
      fontSize: "0.875rem",
      lineHeight: 1.57,
    },
    caption: {
      fontWeight: 400,
      fontSize: "0.75rem",
      lineHeight: 1.66,
    },
    body1: {
      fontSize: "0.875rem",
      lineHeight: 1.57,
    },
    body2: {
      fontSize: "0.75rem",
      lineHeight: 1.66,
    },
    subtitle1: {
      fontSize: "0.875rem",
      fontWeight: 600,
      lineHeight: 1.57,
    },
    subtitle2: {
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: 1.66,
    },
    overline: {
      lineHeight: 1.66,
    },
    button: {
      textTransform: "capitalize",
    },
  },
  // make button have a gradient background, then flat on click
  components: {
    MuiButton: {
      styleOverrides: {
        contained: {
          backgroundColor: '#90c9f9', // Set flat background on hover

          color: "#055480",
          borderRadius: "0.5rem",
          '&:hover': {
            backgroundColor: '#42a4f5', // Set flat background on hover
          },
        },
      },
    },
  }
};

export const theme: Theme = createTheme(themeOptions);
