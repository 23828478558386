import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material"

interface UpdateTypeSelectProps {
    value: string;
    options: string[];
    name: string;
    label: string;
    onChange: (event: SelectChangeEvent<string>) => void;
    error?: string
    maxWidth?: number
    minWidth?: number,
    disabled?: boolean
}

const UpdateTypeSelect: React.FC<UpdateTypeSelectProps> = ({
    value,
    options,
    name,
    label,
    onChange,
    error,
    maxWidth,
    minWidth,
    disabled
}) => {

    return (
        <FormControl fullWidth sx={{
            maxWidth: `${maxWidth}px`,
            minWidth: `${minWidth}px`
        }}>
            <InputLabel id={`${name}-select-label`} color="primary">
                {label}
            </InputLabel>
            <Select
                fullWidth
                id={name}
                name={name}
                label={label}
                value={value}
                onChange={onChange}
                disabled={disabled}
                error={error != undefined}>
                {options.map((option) => (
                    <MenuItem value={option} key={option}>
                        <Typography variant="body1">{option}</Typography>
                    </MenuItem>
                ))}
            </Select>
            {error && <Typography color="error">{error}</Typography>}
        </FormControl>
    )
}

export default UpdateTypeSelect;