import React, { useEffect, useRef } from 'react';

import { useAudioVideo, useContentShareState } from 'amazon-chime-sdk-component-library-react';

import { createCss } from '../../styles/video-styles';

interface ContentShareTileProps {
    tileCount: number;
    viewHeight: number;
    viewWidth: number;
    customCss?: React.CSSProperties
}

export const ContentShareTile: React.FC<React.PropsWithChildren<ContentShareTileProps>> = ({
    tileCount,
    viewHeight,
    viewWidth,
    customCss,
}) => {
    const audioVideo = useAudioVideo();
    const { tileId } = useContentShareState();
    const videoEl = useRef<HTMLVideoElement | null>(null);

    const [css, setCss] = React.useState(createCss(9, 16, viewHeight, viewWidth, tileCount));

    useEffect(() => {
        const css = createCss(9, 16, viewHeight, viewWidth, tileCount);
        setCss(css);
    }, [viewHeight, viewWidth, tileCount]);

    useEffect(() => {
        if (!audioVideo || !videoEl.current || !tileId) {
            return;
        }

        audioVideo.bindVideoElement(tileId, videoEl.current);

        return () => {
            const tile = audioVideo.getVideoTile(tileId);
            if (tile) {
                audioVideo.unbindVideoElement(tileId);
            }
        };
    }, [audioVideo, tileId]);

    return tileId ? (
        <video
            ref={videoEl}
            className={`ch-remote-video--${tileId} content-share-video`}
            style={customCss ? customCss : css}
        />
    ) : null;
};

export default ContentShareTile;