import React, { useEffect } from "react";

import { MeetingStatus, useMeetingManager } from "amazon-chime-sdk-component-library-react";
import { useSearchParams } from "react-router-dom";
import { joinMeeting, joinMeetingMobile } from "../services/chime-service";

import { MeetingInfoContext } from "../context/meeting-info-context";

import MobileUpload from "./mobile-upload";
import DeviceMeeting from "./device-meeting";
import MeetingStatusDisplay from "../components/meeting/meeting-status-display";
import ToastJoin from "../components/meeting/toast-join";
import MobileMeeting from "./mobile-meeting";
import NormalMeetingDrawer from "../components/meeting/normal-meeting-drawer";
import RemoteUserMeeting from "../components/meeting/remote-user-meeting";
import { MeetingJoinType } from "../types";

interface MeetingPageProps {
  meetingType: MeetingJoinType;
}

const MeetingPage: React.FC<MeetingPageProps> = ({
  meetingType = MeetingJoinType.Metro,
}: MeetingPageProps) => {

  const isMobile = navigator.userAgent.includes("Mobile");

  //exact dimensions of RealWare screen

  let widthMatches = Math.abs(window.screen.width - 853) < 10;
  widthMatches = widthMatches || (Math.abs(window.screen.width - 1003) < 10);

  let heightMatches = Math.abs(window.screen.height - 480) < 10;
  heightMatches = heightMatches || (Math.abs(window.screen.height - 564) < 10);

  const isRealWareDevice = widthMatches && heightMatches;

  if (meetingType === MeetingJoinType.Phone && isMobile) {
    if (isRealWareDevice) {
      meetingType = MeetingJoinType.Headset;
    }
  }
  else if (meetingType === MeetingJoinType.Headset && isMobile) {
    if (!isRealWareDevice) {
      meetingType = MeetingJoinType.Phone;
    }
  }

  const [searchParams] = useSearchParams();
  const meetingId = searchParams.get("meetingId") || "";
  const userId = searchParams.get("userId") || "";
  const userName = searchParams.get("userName") || "";
  const jobTitle = searchParams.get("jobTitle") || "";

  const [showSelf, setShowSelf] = React.useState(true);
  const [connected, setConnected] = React.useState(false);
  const [selectedAttendees, setSelectedAttendees] = React.useState<string[]>([]);
  const [showContentShare, setShowContentShare] = React.useState(false);
  const [otherAttendees, setOtherAttendees] = React.useState<string[]>([]);
  const [errorMessage, setErrorMessage] = React.useState<string | null>(null);

  const meetingManager = useMeetingManager();

  useEffect(() => {
    const waitToJoinMeeting = async () => {

      if (errorMessage != null) {
        return;
      }

      if (!meetingId || !userName) {
        setErrorMessage("Missing meeting or user id");
        return;
      }

      try {
        if (meetingType === MeetingJoinType.Phone) {
          await joinMeetingMobile(meetingManager, meetingId, userId, userName, jobTitle);
        }
        else if (meetingType === MeetingJoinType.Headset) {
          await joinMeeting(meetingManager, meetingId, userId, userName, jobTitle, MeetingJoinType.Headset);
        }
        else if (meetingType === MeetingJoinType.Mobile) {
          await joinMeeting(meetingManager, meetingId, userId, userName, jobTitle, MeetingJoinType.Mobile);
        }
        else {
          await joinMeeting(meetingManager, meetingId, userId, userName, jobTitle, undefined);
        }
      }
      catch (e: any) {
        setErrorMessage(e.message);
      }
    };

    waitToJoinMeeting();

  }, [meetingId, userName]);

  useEffect(() => {
    const checkMeetingStatus = (meetingStatus: MeetingStatus) => {
      if (meetingStatus === MeetingStatus.Succeeded && !connected) {
        setConnected(true);
      }
      else if (meetingStatus != MeetingStatus.Succeeded && connected) {
        setConnected(false);
      }
    }

    meetingManager.subscribeToMeetingStatus(checkMeetingStatus);

  }, [meetingManager]);

  return (
    <MeetingInfoContext.Provider value={{
      meetingId,
      userName,
      jobTitle,
      userId,
      meetingType,
      errorMessage,
      setErrorMessage,
      connected,
      setConnected,
      showSelf,
      setShowSelf,
      selectedAttendees,
      otherAttendees,
      setOtherAttendees,
      setSelectedAttendees,
      showContentShare,
      setShowContentShare,
    }}>
      <MeetingStatusDisplay />
      <ToastJoin />
      {meetingType === MeetingJoinType.Headset ? <DeviceMeeting /> : null}
      {meetingType === MeetingJoinType.Phone ? <MobileUpload /> : null}
      {meetingType === MeetingJoinType.Metro ? <NormalMeetingDrawer /> : null}
      {meetingType === MeetingJoinType.Mobile ? <MobileMeeting /> : null}
      {meetingType === MeetingJoinType.Remote ? <RemoteUserMeeting /> : null}
    </MeetingInfoContext.Provider>
  );
};

export default MeetingPage;
