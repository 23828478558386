import * as React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Alert, Button, Grid, TextField } from "@mui/material";

interface LoginFormProps {
  authenticateUser: (email: string, password: string) => void;
  submissionError: string;
}

const LoginForm: React.FC<LoginFormProps> = ({ authenticateUser, submissionError }) => {
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
      password: Yup.string()
        .required("Password is required"),
    }),
    onSubmit: (values) => {
      const { email, password } = values;

      authenticateUser(email, password);
    },
  });

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="email"
              name="email"
              label="Email"
              variant="outlined"
              autoComplete="email"
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.email && Boolean(formik.errors.email)}
              helperText={formik.touched.email && formik.errors.email}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              id="password"
              name="password"
              label="Password"
              type="password"
              variant="outlined"
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              error={formik.touched.password && Boolean(formik.errors.password)}
              helperText={formik.touched.password && formik.errors.password}
            />
          </Grid>

          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Log In
            </Button>
          </Grid>
          {submissionError && (
            <Grid item xs={12}>
              <Alert variant="outlined" severity="error">
                {submissionError}
              </Alert>
            </Grid>
          )}
        </Grid>
      </form>
    </>
  );
};

export default LoginForm;
