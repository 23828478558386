import ReactDOM from "react-dom/client";
import { AuthProvider } from "./context/auth-context";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { ThemeProvider } from "@mui/material/styles";
import { theme } from "./theme/index";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { enAU } from "date-fns/locale";

import {
  MeetingProvider,
} from "amazon-chime-sdk-component-library-react";

import { Toaster } from "react-hot-toast";
import { RecoilRoot } from "recoil";

import Router from "./router";

import { WindowSizeProvider } from "./context/window-size-context";
import ConnectIoT from "./websocket/connect-iot";

const queryClient = new QueryClient();

ReactDOM.createRoot(document.getElementById("root")!).render(
  <>
    <ThemeProvider theme={theme}>
      <RecoilRoot>
        <Toaster toastOptions={{ style: { fontFamily: "Nunito" } }} />
        <MeetingProvider>
          <QueryClientProvider client={queryClient}>
            <WindowSizeProvider>
              <AuthProvider>
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={enAU}
                >
                  <ConnectIoT >
                    <Router />
                  </ConnectIoT>
                </LocalizationProvider>
              </AuthProvider>
            </WindowSizeProvider>
          </QueryClientProvider>
        </MeetingProvider>
      </RecoilRoot>
    </ThemeProvider>
  </>
);
