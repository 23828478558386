import CustomRemoteVideo from "./custom-remote-video";

interface TileGridProps {
    viewHeight: number;
    viewWidth: number;
    showNamePlate: boolean;
    tilesToRender: number[];
    tileIdToAttendeeId: { [tileId: number]: string };
    roster: { [attendeeId: string]: { name?: string } };
}

const TileGrid: React.FC<TileGridProps> = ({
    viewHeight,
    viewWidth,
    showNamePlate,
    tilesToRender,
    tileIdToAttendeeId,
    roster
}) => {

    return tilesToRender.map((tileId) => {
        const attendeeId = tileIdToAttendeeId[tileId] || "";

        return (
            <div key={tileId + "grid"}  >
                <CustomRemoteVideo
                    viewHeight={viewHeight}
                    viewWidth={viewWidth}
                    tileCount={tilesToRender.length} key={tileId}
                    tileId={tileId}
                    name={showNamePlate && roster[attendeeId]?.name || ""} />
            </div >
        )
    })
}

export default TileGrid;