import React from "react";
import { useContext, useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import { AuthContext } from "./context/auth-context";
import { UserType, MeetingJoinType } from "./types";

import MeetingPage from "./routes/meeting-page";
import ProtectedRoute from "./routes/private";
import Login from "./routes/login";
import CalendarPage from "./routes/calendar-page";
import ErrorPage from "./error";
import CallEnded from "./routes/call-ended";
import OrganisationAdminPage from "./routes/organisation-admin-page";
import OrganisationPage from "./routes/organisation-page";
import ForgotPasswordPage from "./routes/forgot-password-page";
import AccountInfoPage from "./routes/account-info-page";
import PatientPage from "./routes/patient-page";
import HelpPage from "./routes/help-page";

const Router = () => {

    const { tryGetStoredSession, isAuthenticated } = useContext(AuthContext);

    const [loading, setLoading] = React.useState(true);
    const [canShow, setCanShow] = React.useState(false);

    useEffect(() => {
        if (!loading || isAuthenticated) {
            return;
        }

        const waitForSessionCheck = async () => {
            const result = await tryGetStoredSession();
            setLoading(false);
            setCanShow(result.length > 0);
        };

        waitForSessionCheck();
    }, []);

    const browserRouter = createBrowserRouter([
        {
            path: "/",
            element: <Login />,
            errorElement: <ErrorPage />,
        },
        {
            path: "/dashboard",
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    authenticationPath="/login"
                    requiredUserLevel={UserType.User}
                    outlet={<CalendarPage />} />
            ),
        },
        {
            path: "/account",
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    requiredUserLevel={UserType.User}
                    authenticationPath="/login"
                    outlet={<AccountInfoPage />} />
            ),
        },
        {
            path: "/adminorganisations",
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    requiredUserLevel={UserType.OrgAdmin}
                    authenticationPath="/login"
                    outlet={<OrganisationAdminPage />} />
            ),
        },
        {
            path: "/organisation",
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    requiredUserLevel={UserType.OrgAdmin}
                    authenticationPath="/login"
                    outlet={<OrganisationPage />} />
            ),
        },
        {
            path: "/patient",
            element: (
                <ProtectedRoute
                    isLoading={loading}
                    isAuthenticated={isAuthenticated || canShow}
                    requiredUserLevel={UserType.User}
                    authenticationPath="/login"
                    outlet={<PatientPage />} />
            ),
        },
        {
            path: "/help",
            element: <ProtectedRoute
                isLoading={loading}
                isAuthenticated={isAuthenticated || canShow}
                requiredUserLevel={UserType.User}
                authenticationPath="/login"
                outlet={<HelpPage />} />

        },
        {
            path: "/login",
            element: <Login />,
        },
        {
            path: "/forgotpassword",
            element: <ForgotPasswordPage />,
        },
        {
            path: `/${MeetingJoinType.Metro}`,
            element: <MeetingPage meetingType={MeetingJoinType.Metro} />,
        },
        {
            path: `/${MeetingJoinType.Remote}`,
            element: <MeetingPage meetingType={MeetingJoinType.Remote} />,
        },
        {
            path: `/${MeetingJoinType.Headset}`,
            element: <MeetingPage meetingType={MeetingJoinType.Headset} />,
        },
        {
            path: `/${MeetingJoinType.Phone}`,
            element: <MeetingPage meetingType={MeetingJoinType.Phone} />,
        },
        {
            path: `/${MeetingJoinType.Mobile}`,
            element: <MeetingPage meetingType={MeetingJoinType.Mobile} />,
        },
        {
            path: "/callEnded",
            element: <CallEnded />,
        },
        {
            path: "*",
            element: <ErrorPage />,
        }
    ]);

    return (
        <RouterProvider router={browserRouter} />
    );

}

export default Router;