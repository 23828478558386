import { Card, Stack, Typography } from "@mui/material";


interface ExpirationCardProps {
    message: string;
}

const ExpirationCard: React.FC<ExpirationCardProps> = ({
    message,
}: ExpirationCardProps) => {

    return (
        <Card >
            <Stack padding={2}>
                <Typography variant="h6">
                    {message}
                </Typography>
            </Stack>
        </Card>
    );
};

export default ExpirationCard;