import { Stack, Typography } from "@mui/material"
import { Meeting } from "../../types"

import { useGetCurrentUser, useGetOrganisation, useGetOrganisationByIds } from "../../store";
import { createMeetingName } from "./create-meeting-name"

interface MeetingInfoCompactProps {
  event: Meeting,
}

const MeetingInfoCompact = ({
  event,
}: MeetingInfoCompactProps) => {

  const { data: user } = useGetCurrentUser();
  const { data: organisations } = useGetOrganisationByIds(event.organisationIds);
  const { data: organisation } = useGetOrganisation(user?.organisationId || "");

  const examineList = createMeetingName(event.meetingName, organisations, organisation);

  let userNameList = examineList.organisationNameList;

  const maxSize = 12;

  if (userNameList.length > maxSize) {
    userNameList = userNameList.substring(0, maxSize) + "...";
  }

  return (
    <Stack
      alignContent={"center"}
      justifyContent={"center"}
      spacing={1}
      direction={"column"}
      sx={{
        height: "100%",
        width: "fit-content",
      }}>
      < Typography variant={"body1"}><b>{event?.patientId || userNameList}</b></Typography>
    </Stack >
  )
}

export default MeetingInfoCompact
