import { Button } from "@mui/material";

import { canJoin, getEventStatus, Meeting, MeetingJoinType } from "../../types";
import { createURL } from "./create-meeting-url";

interface JoinMeetingProps {
    meeting: Meeting;
    joinedUsers: string[];
    userName: string;
    jobTitle: string;
    userId: string;
    meetingType: MeetingJoinType;
}

const JoinMeetingButton = ({
    meeting,
    userId,
    joinedUsers,
    userName,
    jobTitle,
    meetingType,
}: JoinMeetingProps) => {

    const openInNewTab = () => {
        window.open(url, '_blank');
    };

    const url = createURL(meetingType, meeting.meetingId, userName, jobTitle, userId);

    const canJoinNow = canJoin(meeting);

    const buttonMessage = getEventStatus(meeting, joinedUsers);

    return (
        <Button
            onClick={openInNewTab}
            disabled={!canJoinNow}
            variant="contained"
            color="primary"
            size="large">
            {canJoinNow ? "Join Meeting" :
                buttonMessage}
        </Button>
    );
}

export default JoinMeetingButton;