import { IconButton } from "@mui/material"
import CancelIcon from '@mui/icons-material/Cancel';

interface CancelButtonProps {
    handleClose: () => void
}

const CancelButton: React.FC<CancelButtonProps> = ({
    handleClose
}: CancelButtonProps) => {

    return (
        <IconButton
            color="secondary"
            onClick={handleClose}
            aria-label="close"
            sx={{
                position: "absolute",
                top: "0px",
                right: "0px",
            }}>
            <CancelIcon />
        </IconButton>
    );
}

export default CancelButton;