import { Skeleton, Typography } from "@mui/material";
import React, { useEffect } from "react";


interface TelehealthImageProps {
    currentImage: string;
}

const TelehealthImage: React.FC<TelehealthImageProps> = ({
    currentImage,
}) => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [imageError, setImageError] = React.useState<boolean>(false);
    const [selectedImage, setSelectedImage] = React.useState<string>(currentImage);

    useEffect(() => {
        if (currentImage !== selectedImage) {
            setLoading(true);
            setSelectedImage(currentImage || "");
        }
    }, [currentImage]);

    const OnLoadEnd = () => {
        setLoading(false);
        setImageError(false);
    }

    const OnError = () => {
        setLoading(false);
        setImageError(true);
    }

    return (
        <div>
            {loading &&
                <Skeleton variant="rectangular"
                    width={"20vw"}
                    height={"40vh"} />}
            <img
                src={currentImage}
                loading="eager"
                alt="Uploaded"
                onLoad={OnLoadEnd}
                onError={OnError}
                style={{
                    display: loading ? "none" : undefined,
                    width: "100%",
                    height: "100%",
                    maxHeight: "75vh",
                    maxWidth: "75vw",
                }}
            />
            {imageError &&
                <Typography variant="body1">Image Expired</Typography>}
        </div>
    )
}

export default TelehealthImage;