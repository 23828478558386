import React from "react";

import { Stack } from "@mui/material";

import { useContentShareState } from "amazon-chime-sdk-component-library-react";

import ChimeControlBar from "./chime-control-bar";
import ContentShareTile from "./content-share-tile";
import ExcludeSelfVideoTileGrid from "./exclude-self-tile-grid";
import CornerLocalView from "./corner-local-view";


interface NormalMeetingProps {
    viewHeight: number;
    viewWidth: number;
    remote?: boolean;
}

const NormalMeeting: React.FC<NormalMeetingProps> = ({
    viewHeight,
    viewWidth,
    remote = false,
}: NormalMeetingProps) => {

    const { sharingAttendeeId, isLocalUserSharing } = useContentShareState();
    const fullSizeScreenShare = sharingAttendeeId != undefined && !isLocalUserSharing;

    const staticCss: React.CSSProperties =
    {
        position: "absolute",
        display: "flex",
        bottom: "1rem",
        left: "1rem",
        width: "20vw",
        maxHeight: "30vh",
        height: "auto",
        zIndex: "100",
    }

    return (
        <>
            <Stack
                id={"normal-meeting-stack"}
                justifyContent={"center"}
                justifyItems={"center"}
                alignContent={"center"}
                alignItems={"center"}
                minHeight={`${viewHeight}vh`}>
                {sharingAttendeeId &&
                    <ContentShareTile
                        tileCount={1}
                        viewHeight={viewHeight}
                        viewWidth={viewWidth}
                        customCss={isLocalUserSharing ? staticCss : undefined} />}
                {!fullSizeScreenShare &&
                    <ExcludeSelfVideoTileGrid
                        viewHeight={viewHeight}
                        viewWidth={viewWidth}
                        showNamePlate />}

            </Stack>
            <ChimeControlBar showText={false} startWithVideo={remote} contentShare={true} />
            <CornerLocalView
                viewHeight={viewHeight}
                viewWidth={viewWidth}
            />
        </>
    );
};

export default NormalMeeting;