import { PropsWithChildren } from "react"

import { AppBar, Toolbar } from "@mui/material"
import { blue } from "@mui/material/colors"

const TelehealthToolbar: React.FC<PropsWithChildren> = ({
    children,
}) => {

    return (
        <AppBar position="static"
            sx={{
                backgroundColor: blue[100],
            }}>
            <Toolbar disableGutters>
                {children}
            </Toolbar>
        </AppBar>
    )
}

export default TelehealthToolbar