import * as React from 'react';

import { Toolbar, Stack, AppBar } from '@mui/material';

import { MeetingInfoContext } from '../../context/meeting-info-context';
import { MeetingJoinType } from '../../types';
import MeetingControlBar from './meeting-control-bar';

import NormalMeeting from './normal-meeting';
import ShowUploadQR from '../upload/show-upload-qr';
import SelectedAttendeeVideo from './selected-attendee-video';

const RemoteUserMeeting: React.FC = () => {

    const viewHeight = 92;
    const viewWidth = 100;

    const { userName, userId } = React.useContext(MeetingInfoContext);

    const type = `${MeetingJoinType.Headset}`.slice(0, 3);
    const selectedUserName = `${userName}#${type}`
    const selectedUser = `${userId}#${selectedUserName}`;

    return (
        <Stack
            width={"100vw"}
            height={"100vh"}
            sx={{
                display: 'flex',
                backgroundColor: 'black',
            }}>
            <AppBar position="sticky"
                sx={{
                    backgroundColor: "#F5F5F5",
                }}>
                <Toolbar disableGutters>
                    <MeetingControlBar >
                        <ShowUploadQR showText={false} showIcon={true} />
                    </MeetingControlBar>
                </Toolbar>
            </AppBar>
            <NormalMeeting viewHeight={viewHeight} viewWidth={viewWidth} remote={true} />
            <SelectedAttendeeVideo selectedAttendee={selectedUser} name={selectedUserName} />
        </Stack >
    );
}

export default RemoteUserMeeting;