import { useEffect, useState } from 'react';

import { useLocalVideo, useMeetingStatus } from 'amazon-chime-sdk-component-library-react';

import VideocamIcon from '@mui/icons-material/Videocam';
import VideocamOffIcon from '@mui/icons-material/VideocamOff';

import ControlButton from './control-button';

import SelectCameraPopover from './select-camera-popover';
import { Divider } from '@mui/material';

interface CameraProps {
    startWithVideo: boolean;
    showText?: boolean;
    cameraType?: string;
    disabled?: boolean;
    showDropDown?: boolean;
}

const LabelledCameraButton: React.FC<CameraProps> = ({
    startWithVideo,
    showText,
    cameraType,
    disabled = false,
    showDropDown = true
}) => {

    const { isVideoEnabled, toggleVideo } = useLocalVideo();

    const status = useMeetingStatus();

    const [isWaiting, setIsWaiting] = useState<boolean>(false);

    if (cameraType) {
        console.log("camera 0, facing Back".toLowerCase().includes(cameraType))
    }

    const onCameraButton = () => {
        if (status != 1) {
            return;
        }

        if (isWaiting) {
            return;
        }

        setIsWaiting(true);
    }

    // when button is clicked starts wait for toggle video
    useEffect(() => {
        if (status != 1) {
            return;
        }

        if (!isWaiting) {
            return;
        }

        waitThenToggleVideo();

    }, [isWaiting]);

    const waitThenToggleVideo = async () => {

        if (!isWaiting) {
            return;
        }

        // Ensure that a toggle calls is not made if before the button is finished waiting
        await toggleVideo();

        setIsWaiting(false);
    }

    // handles starting with video
    useEffect(() => {
        if (status != 1) {
            return;
        }

        if (isVideoEnabled !== startWithVideo) {
            onCameraButton();
        }
    }, [startWithVideo, status]);

    return (
        <ControlButton
            disabled={disabled}
            activeIcon={<VideocamIcon color='action' />}
            inactiveIcon={<VideocamOffIcon color='action' />}
            active={isVideoEnabled}
            onClick={onCameraButton}
            activeLabel={"Disable Camera"}
            inactiveLabel={"Enable Camera"}
            showText={showText}>
            <Divider orientation="vertical" />
            {
                showDropDown &&
                <SelectCameraPopover
                    startWithVideo={startWithVideo}
                    cameraType={cameraType} />
            }
        </ControlButton >
    );
};

export default LabelledCameraButton;