import DictionaryAutocomplete from "../dictionary-autocomplete";

import { useListUsers } from "../../store";

interface SelectAttendeeProps {
    organisationIds: string[];
    selected: string[];
    requiredIds: string[];
    disabled?: boolean;
    onAdd?: (value: string, selected: string[]) => void;
    onSelect: (selected: string[]) => void
}

const SelectAttendee: React.FC<SelectAttendeeProps> = ({
    organisationIds,
    selected,
    requiredIds = [],
    disabled,
    onAdd,
    onSelect,
}: SelectAttendeeProps) => {

    const { data: users } = useListUsers()

    const filteredUsers: string[] = []
    const notIncludedUsers: string[] = []
    const filteredSelected: string[] = []
    const requiredSelected: string[] = []
    if (users) {

        for (const user of users) {
            if (!user) {
                continue
            }

            const included = organisationIds.includes(user.organisationId)
            if (included) {
                filteredUsers.push(user.userId)

                if (requiredIds.includes(user.userId)) {
                    requiredSelected.push(user.userId)
                }
            }

            if (selected.includes(user.userId)) {
                if (included) {
                    filteredSelected.push(user.userId)
                }
                else {
                    notIncludedUsers.push(user.userId)
                }
            }
        }
    }

    const userDictionary: { [key: string]: string } = {}

    for (const user of users || []) {
        if (!user) {
            continue
        }
        userDictionary[user.userId] = user.fullName
    }

    const dictionarySelected = (selected: string[]) => {

        const finalSelected = [...selected, ...notIncludedUsers]

        onSelect(finalSelected)
    }

    return (
        <DictionaryAutocomplete
            id="attendee"
            selectName="Attendees"
            requiredIds={requiredSelected}
            selected={filteredSelected}
            options={filteredUsers}
            names={userDictionary}
            onAdd={onAdd}
            onChange={dictionarySelected}
            multiple={true}
            disabled={disabled}
        />
    )
}

export default SelectAttendee;