import { useEffect, useState } from 'react';

import { isOptionActive, useLocalVideo, useMeetingManager, useMeetingStatus, useVideoInputs } from 'amazon-chime-sdk-component-library-react';

import { CustomPopOverItemProps } from '../telehealth-popover';
import TelehealthPopover from '../telehealth-popover';

interface CameraProps {
    startWithVideo: boolean;
    cameraType?: string;
}

const SelectCameraPopover: React.FC<CameraProps> = ({
    cameraType,
}) => {

    const meetingManager = useMeetingManager();
    const { devices, selectedDevice } = useVideoInputs();
    const { isVideoEnabled } = useLocalVideo();

    const status = useMeetingStatus();

    const [videoOptions, setVideoOptions] = useState<CustomPopOverItemProps[]>([]);

    const [enabledBefore, setEnabledBefore] = useState<boolean>(false);

    // handles starting with video
    useEffect(() => {
        if (status != 1) {
            return;
        }

        if (!isVideoEnabled) {
            return;
        }

        if (enabledBefore || !cameraType) {
            return;
        }

        for (let i = 0; i < devices.length; i++) {
            if (devices[i].label.toLowerCase().includes(cameraType)) {
                changeSelectedOption(devices[i].deviceId);
                break;
            }
        }

        setEnabledBefore(true);

    }, [isVideoEnabled]);

    const onSelectChanged = (selectedOption: CustomPopOverItemProps) => {
        changeSelectedOption(selectedOption.id);
        console.log("~~Selected Option: " + selectedOption);
    }

    const changeSelectedOption = async (deviceId: string) => {
        try {
            if (isVideoEnabled) {
                await meetingManager.startVideoInputDevice(deviceId);
            } else {
                meetingManager.selectVideoInputDevice(deviceId);
            }
        } catch (error) {
            console.log('VideoInputControl failed to select video input device');
        }
    }

    // Handles drop down menu options
    useEffect(() => {
        const alternateGetDropDown = async () => {
            const deviceList: CustomPopOverItemProps[] = [];

            devices.map(async (device) => {

                const popoverItem: CustomPopOverItemProps = {
                    label: device.label,
                    id: device.deviceId,
                    active: await isOptionActive(device, device.deviceId)
                };

                deviceList.push(popoverItem);
            });

            setVideoOptions(deviceList);
        }

        alternateGetDropDown();
    }, [
        devices,
        selectedDevice,
        isVideoEnabled,
        meetingManager,
        meetingManager.startVideoInputDevice,
    ]);

    return (
        <TelehealthPopover
            options={videoOptions}
            selectedOption={selectedDevice?.toString() || ""}
            setSelectedOption={onSelectChanged}
            openPopOverLabel='Select Camera'
        />
    );
};

export default SelectCameraPopover;